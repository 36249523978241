import React from "react";
//import styled, { keyframes } from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

// const rotate360 = keyframes`
//   from {
//     transform: rotate(0deg);
//   }

//   to {
//     transform: rotate(360deg);
//   }
// `;
// const Spinner = styled.div`
//   margin: 16px;
//   animation: ___CSS_0___ 1s linear infinite;
//   transform: translateZ(0);
//   border-top: 2px solid grey;
//   border-right: 2px solid grey;
//   border-bottom: 2px solid grey;
//   border-left: 4px solid black;
//   background: transparent;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
// `;
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const LoginLoader = () => {
  // return(
  //     // <div style={{ padding: '24px' }}>
  //     //     <Spinner />
  //     //     {/*<div>Fancy Loader...</div>*/}
  //     // </div>
  //
  // )
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};

export default LoginLoader;
