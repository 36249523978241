import React,{useEffect} from 'react';
import { useRecoilState } from 'recoil';
import {ShowNotification} from 'App';
import "firebase/database";
import CloseIcon from '@material-ui/icons/Close';  
import IconButton from '@material-ui/core/IconButton';
import {isEmpty} from 'ramda';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const NotificationManagement = () => {
    const [alertsDictionary,setAlertsDictionary] = useRecoilState(ShowNotification);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar(); 

    useEffect(()=>{
      if(!isEmpty(alertsDictionary)){
        if(alertsDictionary?.Patient){
        const {inviteTime} = JSON.parse(alertsDictionary?.Patient);
        const diffminutes = moment().diff(inviteTime,'minutes')
        let message = alertsDictionary?.message?.replace(
          /[\w\d.:-]*(?=\[UTC\])\[UTC\]/g,
          function() {
            return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
          }
        ).replace(/[0-9]+$/,function(){
          return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
        });
        if(message.search(/minutes$/)!==-1){
          message = message.replace(/\d+(?= minutes$)/,diffminutes)
         // console.log(alertsDictionary,"AlertsDictionary")
        }
        else{
          message = message.concat(` - ${diffminutes} minutes`)
        }
        enqueueSnackbar(message,{
          action:(key) => (
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
              closeSnackbar(key)
              setAlertsDictionary({})}}><CloseIcon fontSize="small" /></IconButton>
        ),
          onClose(){
            setAlertsDictionary({})
          },
          anchorOrigin:{
            vertical: 'top',
            horizontal: 'right',
        }
        });

      }else{
        enqueueSnackbar(alertsDictionary?.message,{
          action:(key) => (
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
              closeSnackbar(key)
              setAlertsDictionary({})}}><CloseIcon fontSize="small" /></IconButton>
        ),
          onClose(){
            setAlertsDictionary({})
          },
          anchorOrigin:{
            vertical: 'top',
            horizontal: 'right',
        }
        });
      }}
      return ()=>{
        //setNotificationOpen(false)
      }
    },[alertsDictionary])
    // useEffect(()=>{
    //   try{
    //   const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
    //   dataBase.ref('alertsList').on('value',(snapshot)=>{
    //     const {AlertsCheck,AlertsMessage} = snapshot.val();
    //     if(AlertsCheck){
    //         setNotificationOpen(true);
    //         setNotificationMessage(AlertsMessage)
    //     }
    //     console.log(snapshot.val(),snapshot,"SnapShot")
    // })}
    // catch(error){
    
    // }
    // },[])
    return (
        <div>
     {/* <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={notificationOpen}
        autoHideDuration={10000}	
        onClose={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref().set({AlertsMessage:'',AlertsCheck:false})
        }}
        message={notificationMessage}
        key={"Notification"}
        action={<IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const updatedNotificationList = notificationList.map(listValues=>{
            //   if(listValues.id===alertsDictionary.id){
            //     return {...alertsDictionary,read:true}
            //   }
            //   return listValues
            // })
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref('alertsList').set(updatedNotificationList)
        }}>
        <CloseIcon fontSize="small" />
      </IconButton>}
      />*/}
    </div>
    )
}

export default NotificationManagement;