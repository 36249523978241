import React, { useEffect, useState } from "react";
import {
  useHistory
} from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Popover,
  Collapse, 
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import API from "../../api";
import "../admin/screens/LoginScreen.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ReactComponent as OmnicureTextIcon} from 'assets/images/OmnicureTextIcon.svg';
import {ReactComponent as HomeIcon} from 'assets/images/Home.svg';
import {ReactComponent as SettingIcon} from 'assets/images/Setting.svg';
import {ReactComponent as Logout} from 'assets/images/Logout_Latest.svg';
import ExpiredMessage from "../common/ExpiredMessage";
import {firebaseIdGlobal as firebaseId,usersID,usersLoginToken} from 'App';
import {useRecoilValue,useSetRecoilState} from 'recoil';
import {assocPath} from 'ramda';
import {Symbols} from "../common/PasswordValidation";
import { withHealthMonitorApiCall } from '../common/HealthMonitor';
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
const usePasswordStyles = makeStyles({
  error: {
    whiteSpace: "pre-wrap",
  },
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  partialCorrect: {
    "& label.Mui-focused": {
      color: "yellow",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
    },
  },
  wrong: {
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
  ullist: {
    ".MuiList-padding": {
      padding: 0,
    },
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "small",
      lineHeight: 1,
      color: "red",
    },
  },
  btndisable: {
    backgroundColor: "#ebebeb",
    cursor: " not-allowed !important",
    pointerEvents: "none !important",
    /* '&:hover': {
        backgroundColor: "#ebebeb",
        cursor:' not-allowed',
        pointerEvents: 'none'
    } */
  },
  toolBar:{
    display:'flex',
    justifyContent:'space-between',
    paddingLeft:'40px !important',
    paddingRight:'40px !important',
    boxShadow: '0px 6px 3px rgba(0, 0, 0, 0.1)'
  },
  
  iconStyle: {
    fontSize: "2rem",
    //marginRight: "10px",
    // margin: '5px 0px 5px 5px'
  },
  menuList: {
    padding: "0px 8px !important",
    '&:hover':{
        background:'#EFF1F5',
        fontWeight:'bold'
    }
},
menuContainer:{
    border: '1px solid #5E6C84',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    fontFamily:'Poppins'
},
});


const passwordExceptionWords = [
  "qwerty",
  "asdfgh",
  "zxcvbn",
  "password",
  "admin",
  "test",
];

function ChangePasswordScreen({ setUserId, userId, userDetails, setUserDetails, setApp, tokenId, setTokenId, refreshId, setRefreshId}) {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseConfirmDownPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleClickOldShowPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseOldDownPassword = () => setShowOldPassword(!showOldPassword);
  const [passField, setPassValue] = React.useReducer(passwordReducer, {
    Error: false,
    Label: "New Password",
    value: "",
    helperText: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup,setPopup] = useState(null);
  const mobileScreen = useMediaQuery(`(max-width:636px)`);
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const firebaseuid=useRecoilValue(firebaseId)
  const usersuid = useRecoilValue(usersID)
  const [open, setOpen] = useState(false)
  const [alertMSG, setAlertMSG] = useState('')
  const setLoginUserToken = useSetRecoilState(usersLoginToken)
  const retryApiGet = (url,header)=>{
    return API.get(url,assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw new Error("Invalid Response")
      }
    }).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              sessionStorage.clear();
              window.location.reload();
              //history.push("/login")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken,
              uid:firebaseuid
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersuid},assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw new Error("Invalid Response")
      }
    }).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              sessionStorage.clear();
              window.location.reload();
              //history.push("/login")
            }
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersuid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseuid
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersuid)){
                return Promise.resolve(res)
              }else{
                throw new Error("Invalid Response")
              }
            }))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }

  const signOut = () => {
    //sessionStorage.removeItem('userId','userDetails','app','userId')
    window.setTimeout(()=>{
      if ('caches' in window) {
        console.log('=caches==', caches)
        caches.keys().then((names) => {
          console.log('==names===', names)
          names.forEach(name => {
            caches.delete(name);
          })
        });
        window.location.reload(true);
        sessionStorage.clear();
  
      }
    },1)

    setUserId(null);
    setUserDetails({});
    setApp(null);
    history.replace("/login");
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const changePasswordScreen = () => {
    history.push("/app/changePassword");
  }

  useEffect(() => {
    passField.value && setPassValue({ value: passField.value });
  }, [userId]);
  const passwordStyles = usePasswordStyles();
  const submit = () => {
    if (passField.value === confirmPassword) {
      retryApiPost("/commonEndpoints/v1/resetPassword", {
        email: userId,
        oldPassword: oldPassword,
        passwordNew: passField.value,
        confirmPassword: confirmPassword,
        // send the app name too
      },
      {
        headers: {
          'Authorization': tokenId,
        }
      })
        .then((res) => {
          if (res.data.status === true) {
            setStatus(true);
            history.push("/app/success");
          } else if (res.data.errorMessage && res.data.errorMessage !== "") {
            setErrorMsg(res.data.errorMessage);
          }
        })
        .catch((error) => {
          if(error.response.status === 403){
            setOpen(true)
            setAlertMSG("User doesn't have permission.")
           // setLoading(false)
        }
        console.log('error.,..', error)
        console.log('error.,..', error.response.status, error.response)
        });
    } else {
      setErrorMsg("New password and confirm new password does not match");
    }
  };
  const login = () => {
    setUserId(null);
    setLoginUserToken(null)
    history.push("/login");
  };
  const navigationHome = () => {
    history.push("/homepage")
  }
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  function passwordReducer(state, action) {
    const { value } = action;
    const { fname, lname } = userDetails;
    let str = [],regcheckValue = value;
    const minMaxLength = /^[\S]{8,32}$/g,upper = /[A-Z]/g,lower = /[a-z]/g,number = /[0-9]/g;
    let specialSymbols = regcheckValue.replace(/[^a-zA-Z0-9]/g, '')
    if (value) {
      specialSymbols = specialSymbols
        .replace(upper, "")
        .replace(lower, "")
        .replace(number, "")
        .replace(/\s/g, "");
      if (specialSymbols) {
        str.push("No Invalid Characters");
        return {
          Error: true,
          Label: "Invalid Password",
          helperText: "Invalid Characters are not allowed",
          value,
        };
      } else {
        for (let name of passwordExceptionWords) {
          if (new RegExp(name, "i").test(value)) {
            str.push("No special words like 'password','qwerty'");
            break;
          }
        }
        if (/\s/g.test(value)) {
          str.push("No whiteSpace Characters");
        } else if (str.length === 0) {
          if (/.com/g.test(value)) {
            str.push("Not include email address");
          }

          if (!upper.test(value)) {
            str.push("Atleast one Upper Case Letter");
          }
          if (!lower.test(value)) {
            str.push("Atleast one Lower Case Letter");
          }
          if (!number.test(value)) {
            str.push("Atleast one number");
          }
          if (!Symbols(value)) {
            str.push("Atleast one Special Character");
          }
          if (
            (new RegExp(fname, "i").test(value) ||
              new RegExp(lname, "i").test(value))
          ) {
            str.push("Not include firstName and LastName");
          }
        }

        if (str.length === 0) {
          if (!minMaxLength.test(value)) {
            str.push("Password Should be of Length 8-32");
          }
        }
        if (str.length !== 0) {
          return {
            Error: true,
            Label: "Invalid Password",
            helperText: str,
            value,
          };
        } else if (str.length === 0) {
          return {
            Error: false,
            Label: "New Password",
            helperText: "",
            value,
          };
        }
      }
    } else {
      return {
        ...state,
        value,
        Error: false,
        Label: "New Password",
        helperText: "",
      };
    }
  }

  //console.log((true&&email&&oldPassword&&passField.value&&confirmPassword&&passField.value===confirmPassword),"Samsung")

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          minHeight: "calc(100vh - 60px)",
          paddingTop: 64,
          flexDirection:'column'
        }}
      >
        <AppBar><Toolbar className={passwordStyles.toolBar}>
      <div><div style={{display:'flex'}}><OmnicureTextIcon /></div></div>
                <div>
                <IconButton className={passwordStyles.iconStyle} color="primary" onClick={navigationHome}>
                  <HomeIcon />
                </IconButton>
        <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={passwordStyles.iconStyle}
                color="primary"                
                onClick={handleMenuClick}
              >
                <SettingIcon />
              </IconButton>
                            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                style={{ margin: "55px 0 0 0" }}
                classes={{paper:passwordStyles.menuContainer}}
              >
                <MenuItem className={passwordStyles.menuList} disabled>
                  <a onClick={changePasswordScreen}>Change Password</a>
                </MenuItem>
              </Menu>
                      <IconButton
              className={passwordStyles.iconStyle}
              color="primary"
              onClick={signOut}
            >
              <Logout />
            </IconButton>
            </div>
        </Toolbar>
        </AppBar>
        <Collapse in={open}>
                <Alert
                action={
                    <IconButton
                    aria-label="close"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setOpen(false);
                        history.push("/homepage")
                    }}
                    >
                    <CloseIcon fontSize="inherit" color="primary" />
                    </IconButton>
                }
                >
                {alertMSG}
                </Alert>
            </Collapse>
        {status === false && (
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              /* justifyContent: "space-evenly", */
              display: "flex",
              
              flexDirection: "column",
              
              ...(mobileScreen
                ? {
                    justifyContent: "space-evenly",
                    width: "95%",
                    height: "75vh",
                    padding:'0 15px'
                  }
                : {
                    padding: "55px 60px 0 60px",
                    minWidth: "440px",
                    minHeight: "445px",
                  }),
            }}
          >
            <h6
              style={{
                marginBottom: "20px",
                color: "grey",
                fontSize: "18px",
              }}
            >
              Change Password
            </h6>
            {/* <TextField
              style={{ marginBottom: "20px" }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
              variant="outlined"
            /> */}
            <TextField
              style={{ marginBottom: "20px" }}
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              label="Old Password"
              variant="outlined"
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOldShowPassword}
                      onMouseDown={handleMouseOldDownPassword}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              error={passField.Error}
              /* className={
                passField.value && !passField.Error && passwordStyles.root
              } */
              FormHelperTextProps={{ classes: passwordStyles }}
              id="outlined-error-helper-text"
              type={showPassword ? "text" : "password"}
              label={passField.Label}
              value={passField.value}
              onChange={(e) => {
                setPassValue({ value: e.target.value });
                setErrorMsg("");
              }}
              variant="outlined"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      style={{ color: "#9d9d9d!important" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    <LightTooltip title="Atleast one uppercase and lowercase Letter, one number, one special character.">
                      <InfoOutlinedIcon
                        style={{ color: "#9b51e0 !important" }}
                        onClick={(e)=>{
                          mobileScreen&&setPopup(e.currentTarget.parentNode.previousSibling)
                        }}
                      ></InfoOutlinedIcon>
                    </LightTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={confirmPassword}
              /* className={
                passField.value &&
                confirmPassword &&
                clsx({
                  [passwordStyles.root]: passField.value === confirmPassword,
                  [passwordStyles.partialCorrect]:
                    passField.value !== confirmPassword &&
                    passField.value.indexOf(confirmPassword) === 0,
                  [passwordStyles.wrong]:
                    passField.value !== confirmPassword &&
                    passField.value.indexOf(confirmPassword) === -1,
                })
              } */
              onChange={(event) => setConfirmPassword(event.target.value)}
              label="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseConfirmDownPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    <LightTooltip title="Atleast one uppercase and lowercase Letter, one number, one special character.">
                      <InfoOutlinedIcon onClick={(e)=>{
                        mobileScreen&&setPopup(e.currentTarget.parentNode.previousSibling)
                      }}></InfoOutlinedIcon>
                    </LightTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              style={{ marginBottom: "0px" }}
              color="primary"
              variant="contained"
              onClick={submit}
              className={
                !(
                  oldPassword &&
                  passField.value &&
                  !passField.Error &&
                  confirmPassword
                ) && passwordStyles.btndisable
              }
            >
              Submit
            </Button>
            <Popover open={Boolean(popup)} 
            anchorEl={popup}
            onClose={()=>{
              setPopup(null)
            }}
            anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} >"Atleast one uppercase and lowercase Letter, one number, one special character."</Popover>
            <div>
              {passField.helperText.length > 0 && (
                <List className={passwordStyles.ullist}>
                  {passField.helperText.map((validationMsg) => (
                    <ListItem>
                      <ListItemText primary={validationMsg} />
                    </ListItem>
                  ))}
                </List>
              )}

              {errorMsg !== "" && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {errorMsg}
                </span>
              )}
            </div>
          </div>
        )}
        {status === true && (
          <div
            style={{
              padding: "50px",
              margin: "auto",
              backgroundColor: "white",
              justifyContent: "space-evenly",
              display: "flex",
              minWidth: "400px",
              flexDirection: "column",
              fontSize: "16px",
            }}
          >
            <span>
              New Password Successfully Updated{" "}
              <a
                style={{ cursor: "pointer", color: "#9b51e0" }}
                onClick={() => {
                  login();
                }}
              >
                Login
              </a>
            </span>
          </div>
        )}
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
}

export default withHealthMonitorApiCall(ChangePasswordScreen);
