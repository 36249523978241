import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@material-ui/core";
  import { createMuiTheme, ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
  import clsx from 'clsx';
  import {PassValidation} from "../common/PasswordValidation";
  import Visibility from "@material-ui/icons/Visibility";
  import VisibilityOff from "@material-ui/icons/VisibilityOff";
  import IconButton from "@material-ui/core/IconButton";
  import InfoIcon from '@material-ui/icons/Info';
  import CheckIcon from '@material-ui/icons/Check';
  import CheckCircle from "@material-ui/icons/CheckCircle";
  import {getAuth,signOut} from 'firebase/auth'; 
  import Tooltip from "@material-ui/core/Tooltip";
  import API from "../../api";
  import {useRecoilValue} from "recoil";
  import {loginUserEmailId, usersLoginToken, usersID, firebaseIdGlobal as firebaseId, authenticationToken} from "../../App";
  

  const useStyles = makeStyles({
    root:{
      paddingRight: '0px !important',
      borderRadius: '8px !important'
    }
  });
  const theme = createMuiTheme({
      MuiTextField:{
        root:{
          border: '1px solid #5E6C84',
          borderRadius: '10px'
        }
      },
     /*  MuiTypography:{
        colorPrimary:{
          color: '#344563'
        }
      }, */
      MuiIconButton:{
        root:{
          color:'#687587'
        }
      },
      MuiTypography: {
        body1: {
          fontSize: '12px',
          color: '#172B4D',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '500',
        }
      },
      MuiLink:{
        root:{
          color:'#344563',
          /* textDecoration: Colors.lightBlue */
        },
        underlineHover:{
         /*  textDecoration: Colors.lightBlue */
        }
      }
  })
  const useDefaultButtonStyles = makeStyles({
    defaultButtonStyles: {
      height: 32,
      border: '2px solid #9B51E0',
      borderRadius: '8px',
      backgroundColor: '#FFF',
      color: '#9B51E0',
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 600
    },
    defaultButtonStylesHover: {
      height: 32,
      border: '2px solid #9B51E0',
      borderRadius: '8px',
      backgroundColor: '#FFF',
      color: '#9B51E0',
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: '#9B51E0',
        color: '#FFF',
        border: '2px solid #9B51E0',
      }
    },
    invertedButtonStyles: {
      height: 40,
      border: '2px solid #9B51E0',
      borderRadius: '8px',
      backgroundColor: '#FFF',
      color: '#9B51E0',
      boxShadow: 'none',
      fontSize: 14,
      fontWeight: 600,
      margin: "0 5px"
    },
    invertedClass: {
      backgroundColor: '#9B51E0',
      color: '#FFF',
    },
    disabledButtonStyles: {
      border: '2px solid #8993A4 !important',
      backgroundColor: '#EFF1F5 !important',
      color: '#5E6C84 !important'
    },
    largerButton: {
      height: 40
    },
    widthBasis: {
      flexBasis: 191
    }
  })
  const approvalListtheme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#9B51E0",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#172B4D",
      },
      success: {
        // This is green.A700 as hex.
        main: "#cb114c",
      },
    },
    /* typography: {
      fontFamily: 'Poppins'
    }, */
    overrides: {
      MuiMenu: {
        paper: {
          border: '1px solid #5E6C84',
          borderRadius: 8
        }
      },
      MuiDialog: {
        paper: {
          minWidth: '42vw',
          borderRadius: 10
        }
      },
      MuiDialogTitle: {
        root: {
          fontWeight: 700,
          fontSize: 18,
          lineHeight: '24px',
          borderBottom: '1px solid #EBECF0'
        }
      },
      MuiSelect: {
        icon: {
          top: 'initial',
          right: '10px'
        }
      },
      MuiDialogContentText: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px'
        }
      },
    }
  })
  const SessionPopup =({sessionTitle,sessionMessage,sessionToken, setUserId, setLogoutUserToken,
    logoutUserToken, refreshId, setTokenId,resetState})=>{
    const classes = useStyles();
    const { invertedButtonStyles,invertedClass } = useDefaultButtonStyles();
    const [open, setOpen]= useState(false);
    const [healthSessionTitle, setHealthSessionTitle] = useState("");
    const [healthSessionMessage, setHealthSessionMessage] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorMsgPassword, setErrorMsgPassword] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [passwordValidation, setPasswordValidation] = useState(false);
    const [lowerCase, setLowerCase] = useState(false)
    const [number, setNumber] = useState(false)
    const [upperCase, setUpperCase] = useState(false)
    const [specialChar, setSpecialChar] = useState(false)
    const [minimumChar, setMinimumChar] = useState(false)
    const [tooltip, setTooltip] = useState(false);
    const [enableInfo, setEnableInfo] = useState(false)
    const [passTicEnableDisable, setPassTicEnableDisable] = useState(true);
    const [message, setMessage] = useState("");
    //const history = useHistory();
    const usersToken = useRecoilValue(usersLoginToken);
    const emeil = useRecoilValue(loginUserEmailId);
    const usersuid = useRecoilValue(usersID);
    const firebaseuid=useRecoilValue(firebaseId);
    const token = useRecoilValue(authenticationToken);
    useEffect(()=>{
        setHealthSessionTitle(sessionTitle)
        setHealthSessionMessage(sessionMessage)
        if(sessionTitle && sessionMessage){
            setOpen(true)
        }
    },[sessionTitle,sessionMessage])
    const handleDialogClose = () => {
        setOpen(false)
        resetState()
    }

    const retryApiPost = (url,data,header)=>{
        return API.post(url,{...data,id:usersuid},{...header,headers:{...header.headers,uid:firebaseuid}}).then((res)=>{
          if(res?.headers?.userid===String(usersuid)){
            return Promise.resolve(res)
          }else{
            throw new Error("Invalid Response")
          }
        }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
          return new Promise(resolve=>{
            API.post("/userEndpoints/v1/renewIdToken",{
              "token": refreshId
            })
            .then((res)=>{
              setTokenId(res.data.idToken)
              if(!res.data.idToken){
                throw error
              }else{
                resolve(API.post(url,{...data,id:usersuid},{headers:{
                  Authorization:res.data.idToken,
                  uid:firebaseuid
                }}).then((res)=>{
                  if(res?.headers?.userid===String(usersuid)){
                    return Promise.resolve(res)
                  }else{
                    throw new Error("Invalid Response")
                  }
                }))
              }
            })
            .catch((error) =>{Promise.reject(error)})
          })
        }else{
          throw error
        }})
      } 
    const keyPress=(event)=>{
        const k = event ? event.which : window.event.keyCode;
        if(k===13){
         // login()
        }
      }

      const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#ffffff',
          color: 'rgba(0, 0, 0, 0.87)',
         /*  maxWidth: 250, */
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
          minWidth: 200
        },
      }))(Tooltip);
      const handleTooltipOpen = () => {
        setTooltip(true);
      };
      const handleTooltipClose = () => {
        setTooltip(false);
      };
      const continuePassWord = () =>{
        setErrorMsgPassword('')
        if(password !==''){
          retryApiPost("commonEndpoints/v1/adminLoginWithPassword",{
              "email":emeil,
              "password":password,
              "token": usersToken
          },
          {
              headers: {
                'Authorization': token,
              },
          }).then((res)=>{
              const response = res.data
              if(response.errorMessage === 'Success'){
                  setOpen(false)
                  resetState()
                  setPassword('')
              } else{
                  setMessage(response.errorMessage)
              }
          })
        }else{
          setMessage("Enter valid password")
        }
      }
      const signout = async () =>{
        const auth = getAuth();
        await signOut(auth)
        retryApiPost("commonEndpoints/v1/logout",{
            "token":logoutUserToken,
            "id": usersuid
          },
          {
            headers: {
              'Authorization': token
            }
          }).then((res)=>{
            sessionStorage.clear();
            sessionStorage.clear();
            //history.push("/login");
            sessionStorage.clear();
            window.location.reload();
            setUserId(null);
            setLogoutUserToken(null)
          })
      }
    
      return(
        <div style={{ minWidth: "auto" }}>
        <ThemeProvider theme={approvalListtheme} >
          <Dialog open={open} scroll={"paper"}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} disableTypography>
              <span>{healthSessionTitle}</span>
              <span className={classes.closeIcon} onClick={handleDialogClose} style={{ position: 'relative', top: 7, left: 16 }}></span>
            </DialogTitle>
            <DialogContent>
               <>
                  <DialogContentText variant='caption'>{healthSessionMessage}</DialogContentText>
                  {healthSessionTitle === 'Auto Locked User' &&<div> <TextField
                    classes={classes}
                    style={{ borderRadius:"7px", height:"54px"}}
                    error={errorPassword}
                    helperText={errorMsgPassword}
                    value={password}
                    placeholder="Password"
                    arial-label="password"
                    size="small"
                    margin="dense"
                    onKeyPress={keyPress}
                    onChange={(event) => {
                        setPassword(event.target.value)
                        setPasswordValidation(false)
                        setLowerCase(false)
                        setUpperCase(false)
                        setSpecialChar(false)
                        setNumber(false)
                        setMinimumChar(false)
                        setMessage('')
                        if(PassValidation(event.target.value)){
                        setPassTicEnableDisable(true)
                        setErrorPassword(false)
                        setErrorMsgPassword('')
                        }else{
                        setPassTicEnableDisable(false)
                        }
                        if(PassValidation(event.target.value)){
                        setPasswordValidation(true)
                        }
                        if(event.target.value.match(/[a-z]/)){
                        setLowerCase(true)
                        }
                        if(event.target.value.match(/[A-Z]/)){
                        setUpperCase(true)
                        }
                        if(event.target.value.match(/[@$!%*?&]/)){
                        setSpecialChar(true)
                        }
                        if(event.target.value.match(/[0-9]/)){
                        setNumber(true)
                        }
                        if(event.target.value.length>=8){
                        setMinimumChar(true)
                        }
                    }}
                    onBlur={()=>{
                        if(!passwordValidation){
                        setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid pasword. Try Again</span>)
                        setErrorPassword(true)
                        setEnableInfo(true)
                        setMessage('')
                        } else{
                        setErrorMsgPassword('')
                        setErrorPassword(false)
                        setEnableInfo(false)
                        }
                    }}
                    /*  label="Password" */
                    /* placeholder="Password" */
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    variant="outlined"
                    /* helperText={errorMsgPassword} */
                    InputProps={{
                        // <-- This is where the toggle button is added.
                        classes:classes,
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            color="primary"
                            >
                            {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                            </IconButton>
                            {passTicEnableDisable && password !=='' &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                            {enableInfo && !passTicEnableDisable && <div>
            
                                <HtmlTooltip
                                onMouseOut={handleTooltipClose}
                                    open={tooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                    <React.Fragment>
                                    <div
                                    style={{
                                        backgroundColor: "#172B4D",
                                        color: "#FFFFFF",
                                        borderRadius: "8px",
                                        width: "200px",
                                        margin: theme.spacing(-1),
                                        boxShadow: theme.shadows[5],
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: "normal",
                                        fontSize: "12px",
                                        lineHeight: "16px"
                                    }}
                                    >
                                    <table style={{ width: "100%", padding:"8px"}}>
                                    <tbody>
                                        <tr>
                                        <td style={{ width: "90%" }}>Password required</td>
                                        </tr>
                                        <tr>
                                        <td style={{ width: "90%" }}>Minimum 8 char</td>
                                        <td style={{ width: "10%" }} align="center">
                                            {minimumChar && (
                                            <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                            )}
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                        <td style={{ width: "10%" }} align="center">
                                            {lowerCase && (
                                            <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                            )}
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                        <td style={{ width: "10%" }} align="center">
                                            {upperCase && (
                                            <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                            )}
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ width: "90%" }}>Atleast 1 number</td>
                                        <td style={{ width: "10%" }} align="center">
                                            {number && (
                                            <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                            )}
                                        </td>
                                        </tr>
                                        <tr>
                                        <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                        <td style={{ width: "10%" }} align="center">
                                            {specialChar && (
                                            <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                            )}
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    </div>
                                    </React.Fragment>
                                }
                                >
                                <IconButton>
                                    <InfoIcon color="primary" onMouseOver={handleTooltipOpen} />
                                </IconButton>
                                </HtmlTooltip>
                            </div>}
                        </InputAdornment>
                        ),
                    }}
                    /> 
                    <div><span style={{fontSize:"12px", color:"red"}}>{message}</span></div>
                    </div>}
               </>
            </DialogContent>
            <DialogActions>
                    {healthSessionTitle ==='Auto Locked User' && <div> 
                        <Button color="primary" className={clsx(invertedButtonStyles, invertedClass)}  variant="contained"  onClick={signout}>Sign out</Button>
                        <Button color="primary" className={clsx(invertedButtonStyles, invertedClass)}  variant="contained" onClick={continuePassWord}>Continue</Button>
                    </div>}
                    {(healthSessionTitle === 'Session Timeout' || healthSessionTitle === 'Locked User' || healthSessionTitle === 'Session Invalidated' || healthSessionTitle === 'Session Timeout') &&<Button color="primary" className={clsx(invertedButtonStyles, invertedClass)}  variant="contained" onClick={signout}>ok</Button>}
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
      )
  }
  export default SessionPopup;