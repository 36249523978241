import React from "react";
import "./contentEditable.css";
import { withTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import {EditorState,CharacterMetadata} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkPlugin from '@draft-js-plugins/anchor';
import Link from '@material-ui/core/Link';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/linkify/lib/plugin.css';
import {stateToHTML} from 'draft-js-export-html';

const linkPlugin = createLinkPlugin({
  Link:function(props) {
    const href = props.contentState.getEntity(props.entityKey)?.data?.url;
    // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
    return <Link href={href} target="_blank" title={href} className="linkHover" onClick={(e) => {if(e.metaKey||e.ctrlKey){
      window.open(href,'_blank')
    }}}>{props.children}</Link>;
  },
});
const inlineToolbarPlugin = createInlineToolbarPlugin();
const linkifyPlugin = createLinkifyPlugin({
  component(props) {
    let {className,...linkProps} = props
    // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
    return <Link{...linkProps} target="_blank" className="linkHover" onClick={(e) => {if(e.metaKey||e.ctrlKey){
      window.open(props.href,'_blank')
    }}} />;
  },
});
const { InlineToolbar } = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin, linkPlugin,linkifyPlugin];

// function setCaret(el, caretPosition) {
//   el.focus();

//   var range = document.createRange(),
//     sel;

//   range.selectNodeContents(el);

//   function findTargetNode(node) {
//   if (node.nodeType > 3) {
//       // Only iterate over elements and text nodes.
//       return;
//     }

//     // If we hit a text node, we need to add the
//     // amount of characters to the overall count.
//     if (node.nodeType === 3) {
//       if (node.length > caretPosition) {
//         return {
//           node: node,
//           offset: caretPosition,
//         };
//       } else if (node.length === caretPosition) {
//         return {
//           node: node,
//           offset: 0,
//         };
//       } else {
//         caretPosition -= node.length;
//       }
//     }
//     // Otherwise, the `nodeType = 1` meaning it is a node.

//     for (var i = 0, nodeObj; i < node.childNodes.length; ++i) {
//       nodeObj = findTargetNode(node.childNodes[i]);

//       if (nodeObj) {
//         return nodeObj;
//       }
//     }
//   }

//   switch (caretPosition) {
//     case -1:
//       // `-1` caret position indicates that we need
//       // to position the caret at the end of the section.

//       // Passing `false` to `collapse` sets the start
//       // and end points at the same end position.
//       range.collapse(false);
//       break;
//     case 0:
//       // `0` caret position indicates that we need
//       // to position the caret at the start of the section.

//       // Passing `true` to `collapse` sets the start
//       // and end points at the same start position.
//       range.collapse(true);

//       break;
//     default:
//       // Otherwise, position the caret at the specific
//       // index.
//       var nodeObj = findTargetNode(el);
//       if (nodeObj) {
//         range.setStart(nodeObj.node, nodeObj.offset);
//         range.setEnd(nodeObj.node, nodeObj.offset);
//       }
//   }

//   sel = window.getSelection();

//   sel.removeAllRanges();

//   sel.addRange(range);
// }

// function setCaretPosition(ctrl, pos) {
//   // Modern browsers
//   if (ctrl.setSelectionRange) {
//     ctrl.focus();
//     ctrl.setSelectionRange(pos, pos);

//     // IE8 and below
//   } else if (ctrl.createTextRange) {
//     var range = ctrl.createTextRange();
//     range.collapse(true);
//     range.moveEnd("character", pos);
//     range.moveStart("character", pos);
//     range.select();
//   }
// }

// const editInputStyles = (theme) => ({
//   root: {
//     padding: "18.5px 14px",
//     fontSize: "16px",
//     textAlign: "left",
//     border: "1px solid rgba(0, 0, 0, 0.23)",
//     borderRadius: "4px",
//     "&:focus-visible": {
//       outline: "none",
//     },
//     "&:focus": {
//       border: `2px solid ${theme.palette.primary.main}`,
//     },
//   },
//   error: {
//     "&:focus": {
//       border: `2px solid red`,
//     },
//   },
// });


// class MyEditor extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {editorState: EditorState.createEmpty()};
//     this.onChange = editorState => this.setState({editorState});
//   }

//   render() {
//     return (
//       <Editor editorState={this.state.editorState} onChange={this.onChange} />
//     );
//   }
// }

class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      html: "",
      linkOn: false,
      linkOff: false,
      outputEnable: false,
      showLegend: false,
      editorState: props.editorMessage ? props.editorMessage : EditorState.createEmpty(),
      editorFocus:false
    };
    this.draftEditor = React.createRef();

  }

  focusEvent = () => {
    this.setState({ showLegend: true });
  };

  blurEvent = () => {
    this.setState({ showLegend: false });
  };

  onChange = editorState => {
    const content = editorState.getCurrentContent()
    const blockMap = content.getBlockMap()

  const blocks = blockMap.map((block) => {
    let altered = false

    const chars = block.getCharacterList().map((char) => {
      let newChar = char

      char
        .getStyle()
        .forEach((type) => {
          altered = true
          newChar = CharacterMetadata.removeStyle(newChar, type)
        })

      return newChar
    })

    return altered ? block.set("characterList", chars) : block
  })

  const updatedContent = content.merge({
    blockMap: blockMap.merge(blocks),
  })

  const newEditorState = EditorState.push(editorState,updatedContent,'change-inline-style')
    this.props.getMessage(newEditorState)
    this.setState({editorState:newEditorState});
    const contentState = editorState.getCurrentContent()
    this.props.updateParent(stateToHTML(contentState))

  }

  onReset = () => {
    this.setState({editorState:EditorState.createEmpty()})
  }

  // componentDidUpdate(prevProps,prevState){
  //   if(prevProps.error!==this.props.error){
  //     if (this.props.error) {
  //       document
  //         .querySelector("#placeholder-992sd").style.setProperty("color", "red");
  //     } else {
  //       document
  //         .querySelector("#placeholder-992sd").style.setProperty("color", "rgba(0, 0, 0, 0.54)");
  //     }
  //   }
  // }

  componentDidMount() {

    

    

    // this.editor.subscribe('blur',(e)=>{
    //   if(document.querySelector('.editable').innerHTML==='<p><br></p>'){
    //     this.editor.setContent('')
    //     this.setState({html:document.querySelector('.editable').innerHTML})
    //       this.props.onChange(document.querySelector('.editable').innerHTML)
    //   }

    //   document.querySelector('.editable').style.border = "1px solid rgba(0, 0, 0, 0.23)"
    //   console.log(document.querySelector('.editable').innerHTML,"LogsBlur")
    // })

    
  }

  handlePaste = (text) => {
    const message = this.state.editorState.getCurrentContent().getPlainText();
    if((message+text).length<=200){
      return 'not-handled'
    }
    return 'handled';
  }

  handleSpecialChar=()=>{
    const messageInputText = this.state.editorState.getCurrentContent().getPlainText();
    return messageInputText.trim().length
  }

  render() {

    const messageInputText = this.state.editorState.getCurrentContent().getPlainText();
   // console.log(messageInputText,messageInputText.length,this.props.error,"RenderError")

    let contentFixedLength =200;
    if(this.props.contentFixedLength){
      contentFixedLength =this.props.contentFixedLength;
    }
    const messageEditorBorderColor = messageInputText?.length < contentFixedLength  ? `2px solid ${this.props.theme.palette.primary.main}` : "2px solid red";
    return (
      <div id="editorContainer">
        {/*<div
          class="editable"
          onClick={(e) => {
          }}
          style={{outline:'none',...(this.props.error ? { border: "1px solid red" } : {})}}
          onKeyPress={(e) => {
            //const messageCharacters = e.target.innerHTML.replaceAll('&nbsp;', ' ').replace(/( |<([^>]+)>)/ig,"");
            const messageCharacters = e.target.innerText;
            if (messageCharacters.length < 200) {
              return;
            } else {
              e.target.style.border = "2px solid red";
              e.preventDefault();
            }
          }}
          onFocus={(e) => {
            this.props.onFocus();
            //const messageCharacters = this.state.html.replaceAll('&nbsp;', ' ').replace(/( |<([^>]+)>)/ig,"");
            const messageCharacters = e.target.innerText;
            if (messageCharacters.length < 200) {
              e.target.style.border = `2px solid ${this.props.theme.palette.primary.main}`;
            } else {
              e.target.style.border = "2px solid red";
            }
          }}
          onBlur={(e) => {
            this.props.onBlur();
            e.target.style.border = "1px solid rgba(0, 0, 0, 0.23)";
          }}
        ></div>
        <FormHelperText error={this.props.error} style={{textAlign:'right',paddingRight:24}}>
          {this.props.helperText}
        </FormHelperText>*/}
        {/*<div id="editor">
      </div>
 */}<div id="draftEditorContainer"  class="dropdownPlaceholder"
 style={{
   fontSize:14,
   fontFamily:'Poppins',
   outline:'none',
   ...(this.props.error ? { border: "1px solid red" } : messageInputText?.length>=contentFixedLength?{border: "1px solid red"}:{}),
   ...this.state.editorFocus&&{border:messageEditorBorderColor}}} 
   onFocus={(e)=>{
    this.setState({editorFocus:true})}} 
    onClick={()=>{
   this.draftEditor.current.focus(); }} 
 onBlur={(e)=>{
  
  this.setState({editorFocus:false})
 }}>
   <Editor   editorState={this.state.editorState} /*handlePastedText={this.handlePaste}*/ onChange={this.onChange} ref={this.draftEditor} /*handleBeforeInput={(char,editorState)=>{
const message = editorState.getCurrentContent().getPlainText();
if(message.length<200){
  return 'not-handled'
} return 'handled'
   }}*/ placeholder="Message"  plugins={plugins} /><InlineToolbar>
   {
     // may be use React.Fragment instead of div to improve perfomance after React 16
     (externalProps) => (
       <div>
         <linkPlugin.LinkButton {...externalProps} />
       </div>
     )
   }
 </InlineToolbar>
   </div>
   <FormHelperText error={this.props.error} style={{...(!this.props.error||messageInputText.trim().length)?{textAlign:'right',paddingRight:24}:{paddingLeft:24}}}>
          {this.props.error?messageInputText.length>contentFixedLength?`${contentFixedLength-messageInputText.length} characters left`:"Please Enter Message":this.state.editorFocus?`${contentFixedLength-messageInputText.length} characters left`:''}
        </FormHelperText>
      </div>
    );
  }
}

export default withTheme(MyComponent);
