import React, { useEffect, useState, useRef, useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import oc_logo from "../../assets/images/omnicure_logo.svg";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { AlertListNotification, unreadNotificationCount, unReadNotificationList, UILevelPermission,getStatusPageUrl,usersID,adminUserDetails } from "App";
//import {DelayNotificationTime,ShowNotification} from 'App'
import { useRecoilState, useRecoilValue, useSetRecoilState, atom } from 'recoil';
import { map, filter } from 'ramda';
import { getDatabase,ref,get,update } from "firebase/database";
import {getAuth,signOut as firebaseSignOut} from "firebase/auth";
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import {ReactComponent as LogoutIcon} from 'assets/icons/Logout.svg';
import {ReactComponent as HomeIcon} from 'assets/images/Home.svg';
import {ReactComponent as SettingIcon} from 'assets/images/Setting.svg';
import {ReactComponent as TrendingIcon} from 'assets/images/trending_up.svg';
import {ReactComponent as MessageIcon} from 'assets/images/Message.svg';
import {ReactComponent as NotificationIcon} from 'assets/images/Notification.svg';
import onlineDot from 'assets/images/greenDot.svg';
import API from 'api';
export const sideBarOpen = atom({
  key: 'sidebarOpen',
  default: false
})



// const useNotificationStyles = makeStyles({
//   root: {
//     minWidth: 250,
//     maxWidth: 350,
//     minHeight: 100,
//     height: 'auto',
//     zIndex: 1201
//   }
// })

// const useToolbarStyles = makeStyles({
//   root: {
//     borderBottom: '2px solid #EBECF0',
//     backgroundColor: '#F7F8FA'
//   }
// })

// const NotificationComponent = () => {
//   const rootStyles = useNotificationStyles();
//   const [NotificationList] = useRecoilState(AlertListNotification);
//   const [unreadNotiList] = useRecoilState(unReadNotificationList);
//   const rowRenderer = ({ index, key, style, parent }) => {
//     return (
//       <div style={style} key={key}>
//         <div style={{ width: '100%', height: '100%', ...index <= unreadNotiList.length - 1 && { width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}>
//           <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', overflowWrap: 'break-word', wordWrap: 'break-word' }}><div>{index <= unreadNotiList.length - 1 ? unreadNotiList[index]?.message : index <= NotificationList.length - 1 ? NotificationList[index]?.message : ''}</div>
//             <div>{(() => {
//               let Patient
//               if (index <= unreadNotiList.length - 1) {
//                 ({ Patient } = unreadNotiList[index]);
//               }
//               else if (index <= NotificationList.length - 1) {
//                 ({ Patient } = NotificationList[index]);
//               }
//               if (Patient) {
//                 const { time } = JSON.parse(Patient);
//                 return moment(new Date(time)).format('LLL')
//               }
//               return ''
//             })()}</div>
//           </div>
//           <Divider />
//         </div>
//       </div>
//     )
//   }
//   return (
//     <Paper classes={rootStyles}>
//       {NotificationList.length ? (
//         <ContainerList
//           rowCount={NotificationList.length + unreadNotiList.length}
//           width={350}
//           height={400}
//           rowHeight={61}
//           rowRenderer={rowRenderer}
//           overscanRowCount={3}
//         />
//         //       <List>
//         //         {intersperse(<Divider />,unreadNotiList.map(({message})=><ListItem style={{padding:0}}>
//         // <ListItemText primary={message} secondary={false/*moment(Number(alertsTime)).format('DD MMM YYYY hh:mm a')*/} style={{backgroundColor:'rgba(0, 0, 0, 0.08)'}} />
//         // </ListItem>))}
//         //               {intersperse(<Divider />),NotificationList.map(({message})=><ListItem style={{padding:0}}>
//         // <ListItemText primary={message} secondary={false/*moment(Number(alertsTime)).format('DD MMM YYYY hh:mm a')*/} />
//         // </ListItem>)}
//         //     </List>
//       ) : (<div style={{ height: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <div>No new Notifications</div>
//       </div>)}
//     </Paper>
//   )
// }

const useAvatarStyles = makeStyles((theme) => ({
  root: {
    //marginRight: 10
  },
  profileCircle: {
    color: "#fafafa",
    backgroundColor: "#B3BAC5",
    width: "40px",
    height: "40px",
    position:'relative',
    overflow:'visible',
    '&::after':{
      content:"''",
      height:12,
      width:12,
      background:`url(${onlineDot})`,
      position:'absolute',
      right:0,
      top:0
    }
  }

}))

const drawerWidth = 260;

const useBadgeStyles = makeStyles({
  badge: {
    color: '#ffffff',
    backgroundColor: '#ff0f0f'

  },
  anchorOriginTopRightRectangle:{
    transform:'scale(0.85) translate(50%, -50%)'
  }
})

const useListStyles = makeStyles({
  listStyles: {
    '&:hover': {
      backgroundColor: '#9B51E0'
    },
    margin: '0 4%',
    color: '#ffffff',
    borderRadius: '12px',
    width: '90%',
    paddingLeft: '16px !important',
    paddigRight: '16px !important'
  },
  selectedColorChange: {

    backgroundColor: '#9B51E0 !important'
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbarStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft:'40px !important',
    paddingRight:'40px !important',
    boxShadow: '0px 6px 3px rgba(0, 0, 0, 0.1)'
  },
  iconStyle: {
    fontSize: "2rem",
    // marginRight: "12px",
    color: 'rgba(0, 0, 0, 0.54)'
    // margin: '5px 0px 5px 5px'
  },
  topIconContainers: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    right: 10,
    top: 0,
    bottom: 0,
  },
  appBar: {
    backgroundColor:'#F7F8FA',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.spacing.unit * 8 + 2,
    width: `calc(100% - ${theme.spacing.unit * 8 + 2}px)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 8 + 1,
      width: `calc(100% - ${theme.spacing.unit * 8 + 1}px)`
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "left",
    marginRight: "13px",
    // marginLeft: 100,
    //padding: theme.spacing(0, -10),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  menuButton: {
    marginRight: 36,
  },
  drawerPaper: {
    width: '260px',
  },
  bgColor: {
    backgroundColor: '#172b4d'
  },
  mobiledrawerPaper: {
    width: 40,
    overflowX: "hidden",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    //width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  // drawer: {
  //   width: "auto",
  //   flexShrink:0,
  //   whiteSpace: "nowrap",
  // },
  mobileDrawer: {
    width: 40,
    overflowX: "hidden",
  },
  // 
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // background: '#4E74DF',
    // background: '#1c48de'
    // background: 'linear-gradient(180deg,#4e73df 10%,#224abe 100%)',
    background: "#172B4D",
  },
  // drawerClose: {
  //   transition: theme.transitions.create("width", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   overflowX: "hidden",

  //   width:theme.spacing.unit * 8 + 2,
  //   [theme.breakpoints.up("sm")]: {
  //     width: theme.spacing(5) + 1,
  //   },
  // },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 8 + 2,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 8 + 1,
    },
    //padding: 5,
    // background: '#4E74DF',
    //background: 'linear-gradient(180deg,#4e73df 10%,#224abe 100%)',
    background: "#172B4D",
    // color: 'rgba(255,255,255,.8)'
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: "auto",
    width: "auto",
    /*  overflowX: 'scroll' */
  },
  reportCcontent: {
    flexGrow: 1,
    padding: "15px 4px",
    overflowX: "scroll",
    width: "auto",
  },
  subMenuPadding: {
    paddingLeft: "45px !important",
  },
  MuiSvgIcon: {
    color: "#ffffff !important",

  },
  // menuList: {
  //   padding: "10px 8px !important",
  //   minWidth:'100px',
  //   bottom: '16px',
  //   left: '1300px',
  //   transformOrigin: '0px 16px',
  // },  
  menuList: {
    padding: "0px 8px !important",
    '&:hover':{
        background:'#EFF1F5',
        fontWeight:'bold'
    }
},
menuContainer:{
    border: '1px solid #5E6C84',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    fontFamily:'Poppins'
},
  menuItem: {
    margin: "65px 0 0 0",
  },
}));
const ListRenderer = React.memo((props) => {
  const { value, page, handleChange,reportsHandler } = props;
  const { subMenu } = page;
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const subMenuNode = useRef(null);
  const { subMenuPadding } = useStyles();
  const { listStyles, selectedColorChange } = useListStyles();
  const handleClick = (e) => {
    page &&
      page.subMenu &&
      page.subMenu.route &&
      page.subMenu.route.includes(pathname) &&
      e.stopPropagation();
    setOpen(!open);
  };
  useEffect(() => {
    //console.log(page,page?.subMenu,"c")
    if (props.open) { page?.subMenu && page?.subMenu.some(subMenuItems => subMenuItems.route === pathname) && setOpen(true) }
    else if (!props.open) {/*page?.subMenu&&page?.subMenu.some(subMenuItems=>subMenuItems.route===pathname)&&*/setOpen(false) }
  }, [props.open])
  useEffect(() => {
    if (props.open) {
      page?.subMenu && !page?.subMenu.some(subMenuItems => subMenuItems.route === pathname) && setOpen(false)
    }
  }, [pathname])

  return (
    <>
      <ListItem
        className={listStyles}
        classes={{ selected: selectedColorChange }}
        selected={reportsHandler?value===page.name:value === page.route}
        button
        id={`${page.name.replaceAll(' ', '')}_navigation`}
        key={reportsHandler?page.name:page.route}
        onClick={() => {
          handleChange(reportsHandler?page.name:page.route);
        }}
      >{page.icon?<ListItemIcon style={{ color: '#FFF', flexBasis: 45, }}>{page.icon}</ListItemIcon>:<ListItemIcon style={{ color: '#FFF', flexBasis: 45, }}><DashboardIcon/></ListItemIcon>}
        
        <ListItemText primaryTypographyProps={{ style: { fontSize:14,...(page.name.length > 20) && { fontSize: 14.5 } ,fontFamily:'Poppins'} }} style={{ ...!props.open && { visibility: 'hidden' } }} primary={page.name} />
        {subMenu ? (
          open ? (
            <ExpandLess onClick={handleClick} />
          ) : (
            <ExpandMore onClick={handleClick} />
          )
        ) : (
          ""
        )}
      </ListItem>
      {subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit ref={subMenuNode}>
          <List component="div">
            {subMenu &&
              subMenu.map(({ name, route }) => (
                <ListItem
                  selected={value === route}
                  id={`${name.replaceAll(' ', '')}_navigation`}
                  button
                  key={route}
                  className={subMenuPadding}
                >
                  <ListItemIcon>{page.subMenu[0].icon}</ListItemIcon>
                  <ListItemText
                    primary={name}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(route);
                    }}
                  />
                </ListItem>
              ))}
          </List>
        </Collapse>
      )}
    </>
  );
});
 export const DrawerComponent = React.memo(
  ({ open, pages, value, handleChange, handleDrawerClose, setOpen, signOut,reportsHandler }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { listStyles, selectedColorChange } = useListStyles();
    const mobileDevice = useMediaQuery(`(max-width:635px)`);
    const setSidebarState = useSetRecoilState(sideBarOpen)
    useEffect(() => {
      setSidebarState(open)
    }, [open])
    //console.log(open,"Initial Stable")
    return (
      <>
        {!mobileDevice ? (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            PaperProps={{
              style: {
                justifyContent: 'space-between',
                // width:'260px'
              }
            }}
            classes={{
              paper: clsx(classes.bgColor, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div>
              <div className={classes.toolbar} style={{ justifyContent: 'flex-start' }}>
                <div style={{ flexBasis: 65, flexShrink: 0 }}>
                  <img
                    style={{
                      float: "left",
                      alignSelf: "center",
                      marginLeft: '20%'
                    }}
                    src={oc_logo}
                    alt={"logo"}
                    importance="low"
                  /></div>
                <div
                  style={{
                    float: "left",
                    marginLeft: "5px",
                    fontSize: "25px",
                    alignSelf: "center",
                    color: "#FFFFFF",
                    fontFamily:'Raleway'
                  }}
                >
                  Omnicure
                </div>
                {/* <div
                  className={classes.toolbar}
                  style={{
                     position:'absolute',
                    top:'calc(50% - 24px)',
                    minHeight:48,
                    borderRadius:'50%',
                    backgroundColor:'#FFFFFF',
                    border:'1px solid #D1D6DD',
                    color: 'black',
                    boxShadow: '0px 0px 12px',
                    // position: 'fixed',
                    background: 'white',
                    width: '33px',
                    height: '33px',
                    // transform: 'rotate(180deg)',
                    // left: '80%',
                    right:'0px',
                  }}
                >
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </div> */}
              </div>
              <div className={classes.drawerHeader}>
                <div
                  style={{
                    boxShadow: "0 0px 5px",
                    borderRadius: "50%",
                    position: "fixed",
                    background: "white",
                    marginTop: "50vh",
                    zIndex:1,
                  }}
                >
                  <IconButton
                    style={{
                      boxShadow: "0 0px 12px",
                      position: "fixed",
                      background: "white",
                      width: "33px",
                      height: "33px"
                    }}
                    aria-label="Menu"
                  //  onClick={this.handleDrawerOpen}
                  >
                    {open ? (
                      <KeyboardArrowLeftOutlinedIcon onClick={handleDrawerClose}
                        style={{ color: "black", position: "fixed" }}
                      />
                    ) : (
                      <KeyboardArrowRightRoundedIcon onClick={handleDrawerClose}
                        style={{ color: "black", position: "fixed" }}
                      />
                    )}
                  </IconButton>
                </div>
              </div>

              <Divider />
              {/* <List>
            {pages.map(page => (
                <ListItem selected={value === page.route} button key={page.route} onClick={() => {
                    handleChange(page.route)
                }}>
                    <ListItemIcon><InboxIcon /></ListItemIcon>
                   <ListItemText primary={page.name}/>
                </ListItem>
            ))}
        </List> */}
              <List id="SideBarList" style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                {pages.filter(({ accessible, name }) => accessible && name !== "Permission").map((page) => (
                  <ListRenderer
                    handleChange={handleChange}
                    value={value}
                    page={page}
                    open={open}
                    reportsHandler={reportsHandler}
                  />
                ))}
              </List>

              {/* <List component="nav" aria-label="main mailbox folders">
            <ListItem button>
            <ListItemIcon>
          </ListItemIcon>
          <ListItemText primary="Log Out"/>
        </ListItem>
                </List>*/}


            </div>
            <div>
              <Divider style={{ backgroundColor: '#FFFFFF', width: '90%', height: 0.56, margin: '0 auto', opacity: 0.5 }} />
              <div>
                <div>
                  <Typography variant="body1">
                    {<style>
                      {/* // href="Copyright,https://www.omnicuremd.com/terms.html"
                  // target="_blank"
                  // className="footerText" */}

                    </style>}
                    <div>
                      {/* <List  component="nav" aria-label="main mailbox folders" style={{ width: '87%', margin: '0 6.7%' }}> */}
                      <List component="nav" aria-label="main mailbox folders" style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }} >

                        {pages.filter(item => item.accessible && item.name === 'Permission').map((page) => (

                          <ListItem
                            className={listStyles}
                            classes={{ selected: selectedColorChange }}
                            selected={value === page.route}
                            button
                            id={`${page.name.replaceAll(' ', '')}_navigation`}
                            key={page.route}
                            onClick={() => {
                              handleChange(page.route);
                            }}
                          >
                            <ListItemIcon style={{ color: '#FFF', flexBasis: 45, }}>{page.icon}</ListItemIcon>
                            <ListItemText primaryTypographyProps={{ style: {fontSize:14,fontFamily:'Poppins', ...(page.name.length > 20) && { fontSize: 14.5 } } }} primary={page.name} />
                          </ListItem>
                        ))}

                        <ListItem onClick={() => { signOut()}} className={listStyles}
                          classes={{ selected: selectedColorChange }} button>
                          <ListItemIcon style={{ color: '#FFF', flexBasis: 45 }}>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText primaryTypographyProps={{style:{fontFamily:'Poppins',fontSize:14}}} style={{ color: '#FFFFFF', fontSize: '14px', fontStyle: 'normal', lineHeight: '24px', fontWeight: '500',fontFamily:'Poppins' }} primary="Log Out" />
                        </ListItem>

                      </List>
                      {/* class='word-Wrap: break-word',
                //   background-color:#8993A4; */}
                      {open && <><span
                        style={{
                          marginTop: "-20px",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "12px",
                          lineHeight: "10px",
                          /* or 160% */
                          fontFamily:'Poppins',
                          textAlign: "center",
                          letterSpacing: "-0.2px",
                          color: "#8993A4",
                        }}
                        class="c" >{`Copyright ©${new Date().getFullYear()} Omnicure.`}<br></br></span>
                        <span
                          style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "10px",
                            /* or 160% */
                            textAlign: "center",
                            fontFamily:'Poppins',
                            letterSpacing: "-0.2px",
                            color: "#8993A4",
                          }} class="c" >All Rights Reserved.</span></>}
                    </div>
                    {/* <p style="color:#8993A4;;">Copyright ©2021 Omnicure.</p>
                  <p style="color:#8993A4;;">All Right Reserved.</p>
                 */}

                  </Typography>


                </div>
              </div>

            </div>
          </Drawer>
        ) : (
          <>
            <Drawer
              variant="temporary"
              anchor={"left"}
              open={!open}
              className={classes.drawer}
              classes={{
                paper: classes.drawer,
              }}
              onClose={() => { setOpen(!open) }}
            >
              <div>
                <div className={classes.toolbar}>
                  <img
                    style={{
                      float: "left",
                      alignSelf: "center",
                    }}
                    src={oc_logo}
                    alt={"logo"}
                  />
                  <div
                    style={{
                      float: "left",
                      marginLeft: "5px",
                      fontSize: "2.1rem",
                      alignSelf: "center",
                      fontFamily:'Raleway'
                    }}
                  >
                    Omnicure
                  </div>
                  <div
                    className={classes.toolbar}
                    style={{
                      float: "left",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                  </div>
                </div>

                <Divider />
                {/* <List>
                {pages.map(page => (
                    <ListItem selected={value === page.route} button key={page.route} onClick={() => {
                        handleChange(page.route)
                    }}>
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                       <ListItemText primary={page.name}/>
                    </ListItem>
                ))}
            </List> */}
                <List id="SideBarList">
                  {pages.filter(({ accessible }) => accessible).map((page) => (
                    <ListRenderer
                      handleChange={handleChange}
                      value={value}
                      page={page}
                      open={open}
                      reportsHandler={reportsHandler}
                    />
                  ))}
                </List>
                <Divider />
              </div>
            </Drawer>
            <Drawer
              open={open}
              variant="permanent"
              className={classes.mobileDrawer}
              classes={{
                paper: classes.mobiledrawerPaper,
              }}
            >
              <div>
                <div className={classes.toolbar}>
                  <img
                    style={{
                      float: "left",
                      alignSelf: "center",
                    }}
                    src={oc_logo}
                    alt={"logo"}
                  />
                  <div
                    style={{
                      float: "left",
                      marginLeft: "5px",
                      fontSize: "2.1rem",
                      alignSelf: "center",
                      fontFamily:'Raleway'
                    }}
                  >
                    Omnicure
                  </div>
                  <div
                    className={classes.toolbar}
                    style={{
                      float: "left",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                  </div>
                </div>

                <Divider />
                {/* <List>
                {pages.map(page => (
                    <ListItem selected={value === page.route} button key={page.route} onClick={() => {
                        handleChange(page.route)
                    }}>
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                       <ListItemText primary={page.name}/>
                    </ListItem>
                ))}
            </List> */}
                <List id="SideBarList">
                  {pages.filter(({ accessible }) => accessible).map((page) => (
                    <ListRenderer
                      handleChange={handleChange}
                      value={value}
                      page={page}
                      open={open}
                      reportsHandler={reportsHandler}
                    />
                  ))}
                </List>
                <Divider />
              </div>
            </Drawer>
          </>
        )}
      </>
    );
  }
);

export const ApplicationBar = React.memo(
  ({ open, handleDrawerOpen, signOut, app, firebaseId }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const classes = useStyles();
    const avatarStyles = useAvatarStyles();
    const userDetails = useRecoilValue(adminUserDetails)
    const badgeValue = useRecoilValue(unreadNotificationCount)
    const [notificationList, setNotificationList] = useRecoilState(AlertListNotification);
    const [unreadNotiList, setUnreadNotiList] = useRecoilState(unReadNotificationList);
    const contactAdminMessageAccess = useRecoilValue(UILevelPermission('View Messages'))
    const systemSettingsMessageAccess = useRecoilValue(UILevelPermission('Manage system settings'))
    const systemAuditAccess = useRecoilValue(UILevelPermission('ADT Audit'))
    const getStatusURL = useRecoilValue(getStatusPageUrl)
    const handleMenuClick = useCallback((event) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
      setAnchorEl(null);
    }, []);
    const changePasswordScreen = useCallback(() => {
      history.push("/app/changePassword");
    }, []);
    const homeNavigation = useCallback(() => {
      history.replace("/homepage")
    }, [])
    const mobileScreen = useMediaQuery(`(max-width:635px)`)
    const badgeStyles = useBadgeStyles();
    const changeTimelineScreen = async () => {
      try{
        const statusPageURL = await getStatusURL()
        window.open(statusPageURL,'_blank')
      }catch(error){
        
      }
    }
    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: mobileScreen ? !open : open,
        })}
      >
        <Toolbar className={classes.toolbarStyles}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton,classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.topIconContainers}>
            <IconButton className={classes.iconStyle} onClick={homeNavigation}><HomeIcon /></IconButton>
            {app === 'adminApp' && <>
              <IconButton id="notificationIcon" className={classes.iconStyle} style={{ marginRight: 0 }} onClick={(e) => {
                history.push("/app/notifications")
                setNotificationList({ ...unreadNotiList, ...notificationList })
                setUnreadNotiList({})
                const database = getDatabase();
                //firebase.database().ref(`adminNotification/${firebaseId}`).get()
                get(ref(database,`adminNotification/${firebaseId}`))
                .then(snapshot => {
                  const listValues = snapshot.val();
                  if(listValues){
                  let unreadValues = filter(val => !(val?.read), listValues)
                  unreadValues = map(val => ({ ...val, read: true }), unreadValues)
                  update(ref(database,`adminNotification/${firebaseId}`),unreadValues)
                  }
                  //firebase.database().ref(`adminNotification/${firebaseId}`).update(unreadValues)
                })
              }}><Badge id="notificationAlert" badgeContent={badgeValue} style={{  }} classes={badgeStyles}><NotificationIcon /> </Badge></IconButton>
              {contactAdminMessageAccess && <IconButton className={classes.iconStyle} style={{ left: 0,position: 'relative' }} onClick={() => {
                history.push("/app/messages")
              }}><MessageIcon /></IconButton>} </>}
            <IconButton className={classes.iconStyle} onClick={changeTimelineScreen}><TrendingIcon /></IconButton>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={classes.iconStyle}
              onClick={handleMenuClick}
            ><SettingIcon /></IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              style={{ margin: "55px 0 0 0" }}
              classes={{paper:classes.menuContainer}}
            >
              <MenuItem className={classes.menuList}>
                <a onClick={changePasswordScreen}>Change Password</a>
              </MenuItem>
              {app === 'adminApp' && systemSettingsMessageAccess && <MenuItem className={classes.menuList}>
                <a onClick={() => { history.push("/app/systemsettings") }}>System Settings</a>
              </MenuItem>}
              {app === 'adminApp'&&systemAuditAccess&&<MenuItem className={classes.menuList}>
              <a onClick={() => { history.push("/app/systemauditscreen") }}>System Audits</a>
              </MenuItem>}
            </Menu>

            <IconButton><Avatar classes={avatarStyles} className={avatarStyles.profileCircle}>{`${userDetails?.fname?.replace(/[\s'.-]/g, "")?.[0]}${userDetails?.lname?.replace(/[\s'.-]/g, "")?.[0]}`}</Avatar></IconButton>
           {/*  <Menu
              id="simple-menu"
              anchorEl={avatoranchorEl}
              keepMounted
              open={Boolean(avatoranchorEl)}
              onClose={handleAvatarMenuClose}
              style={{ margin: "55px 0 0 0" }}
            >
            </Menu>
            <span style={{ color: '#000', fontSize: 14, fontWeight: 600 }}>{`${userDetails?.fname} ${userDetails?.lname}`}</span> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
);

function ResponsiveDrawer({
  path,
  authUser,
  setUserId,
  pageValue,
  pages,
  children,
  shouldShowDrawer,
  app,
  setUserDetails,
  setApp,
  setUserPermission,
  firebaseId,
  setTokenId,
  setRefreshId,
  setUserType,
  setFirebaseId,
  setUsersUid,
  reportsHandler,
  logoutUserToken,
  tokenId,
  setLogoutUserToken,
  refreshId
}) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [value, setValue] = useState(1);
  // const resetDelayNotificationTime = useResetRecoilState(DelayNotificationTime)
  // const resetAlertListNotificationTime = useResetRecoilState(AlertListNotification)
  // const resetUnreadNotificationList = useResetRecoilState(unReadNotificationList)
  // const resetShowNotification = useResetRecoilState(ShowNotification);
  const usersuid = useRecoilValue(usersID);
  useEffect(() => {
    setValue(pageValue);
  }, [pageValue]);

  const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersuid},{...header,headers:{...header.headers,uid:firebaseId}}).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw new Error("Invalid Response")
      }
    }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersuid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseId
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersuid)){
                return Promise.resolve(res)
              }else{
                throw new Error("Invalid Response")
              }
            }))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }

  const signOut = async () => {
    const auth = getAuth();
    await firebaseSignOut(auth)
    retryApiPost("commonEndpoints/v1/logout",{
      "token":logoutUserToken,
      "id": usersuid
    },
    {
      headers: {
        'Authorization': tokenId
      }
    }).then((res)=>{
      /*setUserId(null);
      setUserDetails(null);
      setApp(null);
      setUserPermission(null)
      //setUserAccessId(null)
      setTokenId(null)
      setRefreshId(null)
      setUserType(null)
      setFirebaseId(null)
      setUsersUid(null)
      resetDelayNotificationTime()
      resetAlertListNotificationTime()
      resetUnreadNotificationList()
      resetShowNotification()
      setLogoutUserToken(null)*/
      //history.push("/login");
      sessionStorage.clear();
      window.location.reload();
    })
  };
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDrawerClose = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    history.push(newValue);
  }, []);

  useEffect(() => {
    // console.log(open,"Check the Open")
  }, [open])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {shouldShowDrawer && authUser !== null && pathname !== "/app/success" && (
        <ApplicationBar
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          signOut={signOut}
          firebaseId={firebaseId}
          app={app}
        />
      )}
      {shouldShowDrawer && authUser !== null && pathname !== "/app/success" && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <DrawerComponent
              open={open}
              pages={pages}
              value={value}
              handleDrawerClose={handleDrawerClose}
              handleChange={handleChange}
              setOpen={setOpen}
              signOut={signOut}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <DrawerComponent
              open={open}
              pages={pages}
              value={value}
              handleDrawerClose={handleDrawerClose}
              handleChange={handleChange}
              setOpen={setOpen}
              signOut={signOut}
            />
          </Hidden>
        </nav>
      )}
      <main
        className={
          path === "/dashboard/reports" || path === "/dashboard"
            ? classes.reportCcontent
            : classes.content
        }
        style={{
          ...((pathname === "/login" || pathname === "/app/changePassword") && {
            display: "grid",
          }),
        }}
      >
        <div
          style={{
            ...(shouldShowDrawer &&
              authUser !== null && { paddingTop: "35px" }),
            ...((pathname === "/login" ||
              pathname === "/app/changePassword") && { margin: "auto" }),
            // marginTop: '100px'
          }}
        >
          {children}
        </div>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
