import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MemoryRouter } from 'react-router'
import {RecoilRoot} from 'recoil';
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();
ReactDOM.render(
    <React.StrictMode>
      <MemoryRouter history={customHistory}>
      <RecoilRoot>
      <App />
      </RecoilRoot>
      </MemoryRouter>
    </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
