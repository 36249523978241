import React, {useEffect, useState} from "react";
import API from "../../../api";
import { Button, Typography} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LoginLoader from "../../common/LoginLoader";
import { usersID, firebaseIdGlobal as firebaseId } from 'App';
import {getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence,getIdToken} from 'firebase/auth'
import { assocPath } from 'ramda';
import ExpiredMessage from "../../common/ExpiredMessage";
import {useRecoilValue, useSetRecoilState} from "recoil";
// import "firebase/auth";
// import "firebase/analytics";
// import "firebase/firestore";
// import "firebase/functions";
import {AES256_GCM_ENCRYPT} from "../../common/EncryptionAndDecryption_3"

const TermsAndCondition=({providerUserId,setProviderUserLogin,tokenId, setUserId, setTokenId, refreshId,
    setRefreshId, setProviderUserPassword, providerUserPassword, providerUserEmail, setFirebaseId, setUserType, setUserDetails, setUserPermission, setProviderUserEmail, userId, app})=>{
    const history = useHistory();
    const [terms, setTerms] = useState("");
    const [bottom, setBottom] = useState(true);
    const firebaseuid = useRecoilValue(firebaseId);
    const usersUid = useRecoilValue(usersID);
    const [expiredMsg, setExpiredMsg] = useState(false);
    const [expiredLogin, setexpiredLogin] = useState(false);
    const setFirebaseUId = useSetRecoilState(firebaseId)
    const setUsersID = useSetRecoilState(usersID)
    // const [loginAlert, setLoginAlert] = useState("");
    // const [open, setOpen] = useState(true);
    const retryApiPost = (url, data, header) => {
        // console.log('...res...', url, data, header)
         return API.post(url, { ...data, id: usersUid }, assocPath(['headers', 'uid'], firebaseuid, header)).then((res) => {
           if (res?.headers?.userid === String(usersUid)) {
             //console.log('...res...', res)
             return Promise.resolve(res)
           } else {
             throw new Error("Invalid Response")
           }
         }).catch(function (error) {
           console.log(error?.response?.status, error, "retrypost")
           if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
             return new Promise(resolve => {
               API.post("/userEndpoints/v1/renewIdToken", {
                 "token": refreshId
               })
                 .then((res) => {
                   setTokenId(res.data.idToken)
                   if (!res.data.idToken) {
                     setExpiredMsg(true)
                     if (expiredLogin) {
                       setUserId(null)
                       sessionStorage.clear();
                       window.location.reload();
                       //history.push("/login")
                     }
                     throw error
                   } else {
                     resolve(API.post(url, { ...data, id: usersUid }, {
                       headers: {
                         Authorization: res.data.idToken
                       }
                     }).then((res) => {
                       if (res?.headers?.userid === String(usersUid)&&(res?.data?.id===Number(usersUid))) {
                         return Promise.resolve(res)
                       } else {
                         throw new Error("Invalid Response")
                       }
                     }))
                   }
                 })
                 .catch((error) => { Promise.reject(error) })
             })
           }else {
             Promise.reject(error)
           }
         })
       }
    useEffect(() => {
          if (userId != null) {
            history.replace({pathname:'/homepage'});
          }
        // }
    }, [userId, app]);
    useEffect(()=>{
        API.get("/userEndpoints/v1/termsAndCondition")
        .then((res)=>{
            setTerms(res.data.configuration.value)
        })
        .catch(()=>{
           // setLoading(false)
        })
    },[])
    const accept=()=>{
        API.post("/loginEndpoints/v1/acceptTermsAndCondition",{
            "id":providerUserId
        },
        {
            headers: {
                'Authorization': tokenId,
            },
        })
        .then((res)=>{
            if(res.data.errorId === 108){
                setProviderUserLogin(true)
                history.push("/app/providerpassword")
            }else if(res.data.errorId === 1 && res.data.status === true){
                        let param = {
                          email: providerUserEmail,
                          password: providerUserPassword
                        }
                        //firebase.auth().signInWithEmailAndPassword(providerUserEmail, providerUserPassword)
                        const auth = getAuth();
                        setPersistence(auth, browserSessionPersistence).then(()=>signInWithEmailAndPassword(auth,providerUserEmail, providerUserPassword))
                          .then(async (userCredentials) => {
                            const firebaseUID = userCredentials.user.uid;
                            const EncryptedRefreshToken = await AES256_GCM_ENCRYPT(userCredentials.user.refreshToken)
                            setRefreshId(EncryptedRefreshToken)
                            getIdToken(userCredentials.user,true).then(async function(idToken) {
                              // Send token to your backend via HTTPS
                              // ...
                              const EncryptedToken = await AES256_GCM_ENCRYPT(idToken)
                              setTokenId(EncryptedToken)
                              //setFirebaseToken(idToken)
                
                              const params = {
                                email: providerUserEmail,
                                password: providerUserPassword
                              }
                              if(idToken){
                                  API.post("/commonEndpoints/v1/adminLogin", {
                                    ...params
                                      // send the app name too
                                  },
                                  {
                                    headers: {
                                      'Authorization': EncryptedToken,
                                      uid:firebaseUID
                                    },
                                  })
                                  .then((res,...rest) => {
                                    if (res.data.status === true) {
                                      res.data.adminLoginUser &&
                                      res.data.adminLoginUser.userType &&
                                      setUserType(res.data.adminLoginUser.userType);
                                      const { fname, lname, firebaseUid } = res.data.adminLoginUser;
                                      setUserId(providerUserEmail);
                                      setFirebaseId(firebaseUid);
                                      setUserDetails({ fname, lname });
                                      res.data.adminLoginUser.roles && setUserPermission("active");
                                      //setUserAccessId(res.data.user.id)
                                      //setPermission(res.data.user.roles.map(({name})=>name))
                                      //CHECK THIS COMMENT BEFORE DELETING ROLES
                                      //setRoles(res.data.user.roles.map(({name})=>name))
                                      setUsersID(res?.data?.adminLoginUser?.id)
                                      setFirebaseUId(res?.data?.adminLoginUser?.firebaseUid)
                                      //setLoginFooter(false)
                                    } /*else {
                                      // setOpen(true);
                                      // setLoginAlert(res.data.errorMessage);
                                    }*/
                                   /*  if(res.data.errorId === 107){
                                      setProviderUserLogin(true)
                                      history.push("/app/termsandconditions")
                                      setProviderUserId(res.data.userId)
                                      setProviderUserToken(res.data.token)
                                      setProviderUserEmail(res.data.email)
                                      setProviderUserPassword(password)
                                    }else if(res.data.errorId === 108){
                                      history.push("/app/providerpassword")
                                      setProviderUserLogin(true)
                                      setProviderUserId(res.data.userId)
                                      setProviderUserToken(res.data.token)
                                      setProviderUserEmail(res.data.email)
                                      setProviderUserPassword(password)
                                    } */
                                  })
                                  .catch( (error)=> {
                                    // if(error.response && error.response.status === 504){
                                    //   // setLoginAlert('Server did not response please try again...')
                                    // } else if(error.response && error.response.status === 500){
                                    //   // setLoginAlert('')
                                    // }
                                  });
                                }
                            }).catch((error) => {
                              // Handle error
                            });
                          })
                          .catch((error)=> {
                            if(error.code === 'auth/user-disabled'){
                              API.post("userEndpoints/v1/adminLoginFailed", {
                                ...param
                              })/*.then((res)=>{
                                //setLoginAlert(res.data.errorMessage);
                                // setOpen(true)
                              }).catch(()=>{})*/
                            }
                            /*if (error && error.code === 'auth/user-not-found'||error.code === 'auth/wrong-password'||error.code === 'auth/too-many-requests') {
                              //setLoginAlert("Please enter valid email or password");
                              // setOpen(true)
                            }*/
                          });
                       /*  } else{
                          setLoading(false);
                        } */
                      /* } else{
                        setLoading(false);
                        setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>)
                        setErrorEmail(true)
                      } */
               // };
            }else{
                console.log('...login...')
                history.push("/login")
            }
        })
    }
    const cancel=()=>{
        history.push("/login")
    }
    const handleScroll = (e) => {
        if((e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight) || (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight)){
            setBottom(false)
        }
    };
    
    return(
        <div onScroll={handleScroll}>
            {terms && <div style={{maxWidth:"600px",margin:"50px auto", backgroundColor:"#ffffff",}}>
                <div style={{backgroundColor:"white",float:"left",width:"100%"}}>
                    <h2 style={{padding:"18px 0px 0px 11px",float:"left",fontWeight:"500",fontSize:"1.25rem",fontFamily:"Roboto,Helvetica,Arial,sans-serif",letterSpacing:"0.007em"}}>Terms and Conditions </h2> 
                
                </div>

                <div style={{fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontSize: "12px",
                    float:"left",
                    padding:"15px",
                    backgroundColor:"#ffffff",
                    height: "calc(100vh - 216px)",
                    overflow:"auto",
                    textAlign:"left",

                }}>
                <p style={{color:"#9B51E0",fontSize:"initial",paddingBottom:"18px"}}>End user Licence Agreement</p>
                    <Typography  dangerouslySetInnerHTML={{ __html: terms }}>
                    </Typography>
                </div>

                <div style={{paddingRight:"15px",backgroundColor:"white", Width:"100%", paddingLeft:"225px"}}>
                    <Button
                        style={{margin:"5px",textTransform:"initial"}}
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        color="primary"
                        style={{margin:"5px",textTransform:"initial"}}
                        disabled={bottom}
                        onClick={()=>{
                            accept();
                        }}
                    >
                        I agree to the Terms and Conditions
                    </Button>
                </div>
            </div>}
            {terms ==='' && <div style={{maxWidth:"600px",margin:"auto", padding:"10px 0"}}><LoginLoader /></div>}
            {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin} /></div>}
        </div>
    )
}
export default TermsAndCondition