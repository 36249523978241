import axios from "axios";
import { AES256_GCM_ENCRYPT, AES256_GCM_decrypt } from 'components/common/EncryptionAndDecryption_3';

const API = axios.create({
  baseURL: '/api', //REACT_APP_API,
});

API.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let { data, method } = config;

    if(!process.env.REACT_APP_PRODUCTION_BUILD){
      console.log(config.url, "Config", data);
    }
    if (method === 'post' && data && !(data.hasOwnProperty('token'))) {
      const EncryptPromise = AES256_GCM_ENCRYPT(JSON.stringify(data))
      EncryptPromise.then(encryptedValue => {
        const newConfig = { ...config, data: { encryptedValue } }
        resolve(newConfig)
      }).catch(error => {
        reject(error)
      })
    } else {
      resolve(config)
    }
  })
}, function (error) {
  return Promise.reject(error)
},{runWhen({url}){
  return url !== '/userEndpoints/v1/versioninforesponse/Website'
}})

API.interceptors.response.use(function (response) {
  return new Promise((resolve, reject) => {
    if (response?.data?.encryptedValue) {
      AES256_GCM_decrypt({ data: response.data }).then(decryptedValue => {
        if(!process.env.REACT_APP_PRODUCTION_BUILD){
        console.log(response.config.url, JSON.parse(decryptedValue), "Response_Config")
        }
        const newResponse = { ...response, data: JSON.parse(decryptedValue) };
        resolve(newResponse)
      }).catch(error => {
        // console.log(error,"InterceptorsError")
        reject(error)
      })
    }
    else {
      resolve(response)
    }
  })
}, function (error) {
  // console.log(error,"Interceptors_Error")
  return Promise.reject(error);
})

export default API;
