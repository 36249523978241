import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import {TextField, Button, Link, InputAdornment} from "@material-ui/core";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import logo from "../../../../src/assets/images/Logo.png";
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import Colors  from "../../dashboard/color";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ElipsOne from "../../../assets/images/Ellipse 1.png";
import ElipsTwo from "../../../assets/images/Ellipse 2.png";
import ElipsThree from "../../../assets/images/Ellipse 3.png";
import Vector from "../../../assets/images/Vector.png";
import { firebaseIdGlobal as firebaseId, usersID, usersLoginToken } from 'App';
import {getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence,getIdToken} from 'firebase/auth'
import {assocPath} from 'ramda';
import {PassValidation} from "../../common/PasswordValidation";
import {useRecoilValue, useSetRecoilState} from "recoil";
// import "firebase/auth";
// import "firebase/analytics";
// import "firebase/firestore";
// import "firebase/functions";
import {AES256_GCM_ENCRYPT} from "../../common/EncryptionAndDecryption_3"
const useStyles = makeStyles({
  root:{
    paddingRight: '0px !important',
    borderRadius: '8px !important'
  }
});
const theme = createMuiTheme({
    MuiTextField:{
      root:{
        border: '1px solid #5E6C84',
        borderRadius: '10px'
      }
    },
   /*  MuiTypography:{
      colorPrimary:{
        color: '#344563'
      }
    }, */
    MuiIconButton:{
      root:{
        color:'#9B51E0'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '12px',
        color: '#172B4D',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
      }
    },
    MuiLink:{
      root:{
        color:'#344563',
        textDecoration: Colors.lightBlue
      },
      underlineHover:{
        textDecoration: Colors.lightBlue
      }
    },
    overrides:{
      MuiRadio: {
        root:{
          color:"#9B51E0",
        },
        colorSecondary: {
          '&$checked': {
            color: '#9B51E0',
          },
        },
      },
      MuiIconButton:{
        root:{
          padding: "12px 5px"
        }
      }
    }
})
const ProviderUserPassword = ({setUserId, userId, setTokenId, refreshId, setRefreshId, setUserDetails, setUserType, providerUserToken, providerUserEmail, setProviderUserPassword, setFirebaseId, setUserPermission, acceptedProvider, providerUserLogin}) =>{
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordStatus, setChangePasswordStatus] = useState(true);
  const [successMsg, setSuccessMsg] = useState(false);
  const [open, setOpen] = useState(true);
  const [loginAlert, setLoginAlert] = useState("");
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfimShowPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const classes = useStyles();
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [minimumChar, setMinimumChar] = useState(false);
  const [confirmLowerCase, setConfirmLowerCase] = useState(false);
  const [confirmNumber, setConfirmNumber] = useState(false);
  const [confirmUpperCase, setConfirmUpperCase] = useState(false);
  const [confirmSpecialChar, setConfirmSpecialChar] = useState(false);
  const [confirmMinimumChar, setConfirmMinimumChar] = useState(false);
  const [enableInfo, setEnableInfo] = useState(false);
  const [confirmEnableInfo, setConfirmEnableInfo] = useState(false);
  const [infoTicEnableDisable, setInfoTicEnableDisable] = useState(false);
  const [confirmInfoTicEnableDisable, setConfirmInfoTicEnableDisable] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorMsgConfirmPassword, setErrorMsgConfirmPassword] = useState("");
  const [tooltipNew, setTooltipNew] = useState(false);
  const [tooltipConfirm, setTooltipConfirm] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const firebaseuid=useRecoilValue(firebaseId);
  const usersuid = useRecoilValue(usersID);
  const setFirebaseUId = useSetRecoilState(firebaseId)
  const setUsersID = useSetRecoilState(usersID)
  const setLoginUserToken = useSetRecoilState(usersLoginToken)
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
     /*  maxWidth: 250, */
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      minWidth: 200
    },
  }))(Tooltip);
  const handleTooltipClose = () => {
    setTooltipNew(false)
    setTooltipConfirm(false)
  };
  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              sessionStorage.clear();
              window.location.reload();
              //history.push("/login")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersuid},assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)||url.startsWith('/userEndpoints')){
        return Promise.resolve(res)
      }else{
        throw new Error("Invalid Response")
      }
    }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token":refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              sessionStorage.clear();
              window.location.reload();
              //history.push("/login")
            }
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersuid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseuid
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersuid)||url.startsWith('/userEndpoints')){
                return Promise.resolve(res)
              }else{
                throw new Error("Invalid Response")
              }
            }))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }

  useEffect(() => {
      if (userId != null) {
        history.replace({pathname:'/homepage'});
      }
    // }
}, [userId]);
  useEffect(() =>{
    if(expiredLogin){
      setUserId(null)
      sessionStorage.clear();
      window.location.reload();
      //history.push("/login")
    }
  },[expiredLogin])

  const changePassword = () => {
      if(newPassword === confirmPassword){
        setProviderUserPassword(newPassword)
        setLoading(true);
        //setErrorMsg("")
        retryApiPost("/userEndpoints/v1/set/new/password",{
          "email": providerUserEmail,
          "passwordNew": newPassword,
          "token": providerUserToken
        })
        .then((res)=>{
         // setErrorMsg("")
          if(!acceptedProvider){
            setChangePasswordStatus(false);
            setSuccessMsg(true);
            setLoading(false);
            setButtonStatus(true)
          }else if(acceptedProvider){
                setLoading(true);
                setLoginAlert('')
                    let param = {
                      email: providerUserEmail,
                      password: newPassword
                    }
                    //firebase.auth().signInWithEmailAndPassword(providerUserEmail, newPassword)
                    const auth = getAuth();
                    setPersistence(auth, browserSessionPersistence).then(()=>signInWithEmailAndPassword(auth,providerUserEmail, newPassword))
                      .then(async (userCredentials) => {
                        const firebaseUID = userCredentials.user.uid;
                        const EncryptedRefreshToken = await AES256_GCM_ENCRYPT(userCredentials.user.refreshToken)
                        setRefreshId(EncryptedRefreshToken)
                        //firebase.auth().currentUser.getIdToken( true)
                        getIdToken(userCredentials.user,true)
                        .then(async function(idToken) {
                          // Send token to your backend via HTTPS
                          // ...
                          const EncryptedToken = await AES256_GCM_ENCRYPT(idToken)
                          setTokenId(EncryptedToken)
            
                          const params = {
                            email: providerUserEmail,
                            password: newPassword
                          }
                          if(idToken){
                              API.post("/commonEndpoints/v1/adminLogin", {
                                ...params
                                  // send the app name too
                              },
                              {
                                headers: {
                                  'Authorization': EncryptedToken,
                                  uid:firebaseUID
                                },
                              })
                              .then((res,...rest) => {
                                if (res.data.status === true) {
                                  setLoading(false);
                                  res.data.adminLoginUser &&
                                  res.data.adminLoginUser.userType &&
                                  setUserType(res.data.adminLoginUser.userType);
                                  const { fname, lname, firebaseUid } = res.data.adminLoginUser;
                                  setUserId(providerUserEmail);
                                  setFirebaseId(firebaseUid);
                                  setUserDetails({ fname, lname });
                                  res.data.adminLoginUser.roles && setUserPermission("active");
                                  //setUserAccessId(res.data.user.id)
                                  //setPermission(res.data.user.roles.map(({name})=>name))
                                  //CHECK THIS COMMENT BEFORE DELETING ROLES
                                  //setRoles(res.data.user.roles.map(({name})=>name))
                                  setUsersID(res?.data?.adminLoginUser?.id)
                                  setFirebaseUId(res?.data?.adminLoginUser?.firebaseUid)
                                  //setLoginFooter(false)
                                 
                                } else {
                                  setLoading(false);
                                  setOpen(true);
                                  setLoginAlert(res.data.errorMessage);
                                }
                              })
                              .catch( (error)=> {
                                setLoading(false);
if(error.response && error.response.status === 504){
                                 
                                  setLoginAlert('Server did not response please try again...')
                                } else if(error.response && error.response.status === 500){
                                 
                                  setLoginAlert('')
                                }
                              });
                            }
                        }).catch((error) => {
                          setLoading(false);
                          setLoginAlert("Please enter valid email or password");
                          // Handle error
                        });
                      })
                      .catch((error)=> {
                        if(error.code === 'auth/user-disabled'){
                          API.post("userEndpoints/v1/adminLoginFailed", {
                            ...param
                          }).then((res)=>{
                            setLoginAlert(res.data.errorMessage);
                            setOpen(true)
                          })
                        }
                        setLoading(false);
                        if ((error && error.code === 'auth/user-not-found')||error.code === 'auth/wrong-password'||error.code === 'auth/too-many-requests') {
                          setLoginAlert("Please enter valid email or password");
                          setOpen(true)
                        }
                      });
          }else{
            setLoginAlert(res?.data?.errorMessage)
            setOpen(true)
            console.log('.....else......')
          }
        })
        .catch((error)=>{
          setLoading(false);
          
if(error&&error.response && error.response.status === 504){
            
            setLoginAlert('Server did not response please try again...')
          } else if(error&&error.response && error.response.status === 500){
            
            setLoginAlert('')
          }
        })
      }else {
        /* setErrorMsg(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>) */
      }
  }
  
  const login = () =>{
    setSuccessMsg(false)
    history.push("/login")
    setLoginUserToken(null)
  }
   
  return (
    <div style={{display:"grid", height:"100vh"}}>
      {loginAlert !== "" && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "auto",
          width: "100%",
          display: "initial",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{padding:"0px 0 0 0"}}>
          <div 
            style={{display:"initial",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"0px"
          }}>
            <img src={logo} />
          </div>
          <div 
          style={{
          fontFamily:"Poppins",
          fontStyle:"normal",
          fontWeight:"400",
          fontSize:"13px",
          lineHeight:"16px",
          width:"490px",
          margin:"auto",
          padding:"0px 0 30px 0",
          color:"#344563"
          }}>
            <p>Welcome to Omnicure Command Center</p>
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            /* justifyContent: "space-evenly", */
            display: "flex",
            flexDirection: "column",
            borderRadius:"10px",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              /* padding: "24px", */
              width: "365px",
              minHeight: "300px"
            }
          }}
        >
          {changePasswordStatus && !successMsg && <div>
          <h6
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "-.2px",
                color: "#010353",
                padding: "24px 24px 8px 24px"
              }}
            >
              New Password
            </h6>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"400",
                fontSize:"13px",
                lineHeight:"16px",
                padding:"8px 25px",
                color:"#344563"
                }}>
              <p>Enter your new password below.</p>
            </div>
            <ThemeProvider theme={theme}>
              <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
                <TextField
                  classes={classes}
                  style={{ borderRadius:"7px", height:"40px"}}
                  error={errorPassword}
                  helperText={errorMsgPassword}
                  value={newPassword}
                  placeholder="Enter new password"
                  arial-label="Enter new password"
                  size="small"
                  margin="dense"
                  onChange={(event) => {
                    setNewPassword(event.target.value)
                    setPasswordValidation(false)
                    setLowerCase(false)
                    setUpperCase(false)
                    setSpecialChar(false)
                    setNumber(false)
                    setMinimumChar(false)
                    if(event.target.value.length>0){
                      setEnableInfo(true)
                    }else{
                      setEnableInfo(false)
                    }
                    if(PassValidation(event.target.value)){
                      setInfoTicEnableDisable(true)
                      setErrorPassword(false)
                      setErrorMsgPassword('')
                    }else{
                      setInfoTicEnableDisable(false)
                    }
                    if(PassValidation(event.target.value)){
                      setPasswordValidation(true)
                      if(confirmPasswordValidation && event.target.value === confirmPassword){
                        setButtonStatus(false)
                        setErrorMsgConfirmPassword('')
                      }else if(confirmPasswordValidation && event.target.value !== confirmPassword){
                        setButtonStatus(true)
                        setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Password does not match</span>)
                      }
                    }else{
                      setButtonStatus(true)
                    }
                    if(event.target.value.match(/[a-z]/)){
                      setLowerCase(true)
                    }
                    if(event.target.value.match(/[A-Z]/)){
                      setUpperCase(true)
                    }
                    if(event.target.value.match(/[@$!%*?&]/)){
                      setSpecialChar(true)
                    }
                    if(event.target.value.match(/[0-9]/)){
                      setNumber(true)
                    }
                    if(event.target.value.length >= 8){
                      setMinimumChar(true)
                    }
                  
                  }}
                  onBlur={()=>{
                    if(!passwordValidation){
                      setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid password. Try Again</span>)
                      setErrorPassword(true)
                      setButtonStatus(true)
                    }else if(confirmPassword !== newPassword){
                      setButtonStatus(true)
                    } else{
                      setErrorMsgPassword('')
                      setErrorPassword(false)
                      if(confirmPasswordValidation){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }
                  }}
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  InputProps={{
                    classes:classes,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          color="primary"
                        >
                          {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                        </IconButton>
                        {infoTicEnableDisable &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                          {enableInfo && !infoTicEnableDisable && <div>
          
                            <HtmlTooltip
                              onMouseOut={handleTooltipClose}
                                open={tooltipNew}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <React.Fragment>
                                  <div
                                  style={{
                                    backgroundColor: "#172B4D",
                                    color: "#FFFFFF",
                                    borderRadius: "8px",
                                    width: "200px",
                                    margin: theme.spacing(-1),
                                    boxShadow: theme.shadows[5],
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px"
                                  }}
                                  >
                                  <table style={{ width: "100%", padding:"8px"}}>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "90%" }}>Password required</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Minimum 8 char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {minimumChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {lowerCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {upperCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 number</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {number && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {specialChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <IconButton>
                                <InfoIcon color="primary" onMouseOver={()=>{
                                  setTooltipNew(true)
                                }} />
                              </IconButton>
                            </HtmlTooltip>
                          </div>}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  classes={classes}
                  style={{ borderRadius:"7px", height:"40px"}}
                  error={errorConfirmPassword}
                  helperText={errorMsgConfirmPassword}
                  value={confirmPassword}
                  placeholder="Confirm new password"
                  arial-label="Confim new password"
                  size="small"
                  margin="dense"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value)
                    setConfirmPasswordValidation(false)
                    setConfirmLowerCase(false)
                    setConfirmUpperCase(false)
                    setConfirmSpecialChar(false)
                    setConfirmNumber(false)
                    setConfirmMinimumChar(false)
                    if(event.target.value){
                      setConfirmEnableInfo(true)
                    }else{
                      setConfirmEnableInfo(false)
                    }
                    if(PassValidation(event.target.value)){
                      setConfirmInfoTicEnableDisable(true)
                      setErrorConfirmPassword(false)
                      setErrorMsgConfirmPassword('')
                    }else{
                      setConfirmInfoTicEnableDisable(false)
                    }
                    if(PassValidation(event.target.value)){
                      setConfirmPasswordValidation(true)
                      if(passwordValidation && (newPassword === event.target.value)){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }else{
                      setButtonStatus(true)
                    }
                    if(event.target.value.match(/[a-z]/)){
                      setConfirmLowerCase(true)
                    }
                    if(event.target.value.match(/[A-Z]/)){
                      setConfirmUpperCase(true)
                    }
                    if(event.target.value.match(/[@$!%*?&]/)){
                      setConfirmSpecialChar(true)
                    }
                    if(event.target.value.match(/[0-9]/)){
                      setConfirmNumber(true)
                    }
                    if(event.target.value.length >= 8){
                      setConfirmMinimumChar(true)
                    }
                  
                  }}
                  onBlur={()=>{
                    if(!confirmPasswordValidation){
                      setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid Confirm password. Try Again</span>)
                      setButtonStatus(true)
                      setErrorConfirmPassword(true)
                     // setConfirmEnableInfo(true)
                    }else if(confirmPassword !== newPassword){
                      setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Password does not match</span>)
                      setButtonStatus(true)
                      setErrorConfirmPassword(true)
                    } else{
                      setErrorMsgConfirmPassword('')
                      setErrorConfirmPassword(false)
                     // setConfirmEnableInfo(false)
                     if(passwordValidation){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  InputProps={{
                    classes:classes,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickConfimShowPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          color="primary"
                        >
                          {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                        </IconButton>
                        {confirmInfoTicEnableDisable &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                          {confirmEnableInfo && !confirmInfoTicEnableDisable && <div>
          
                            <HtmlTooltip
                              onMouseOut={handleTooltipClose}
                                open={tooltipConfirm}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <React.Fragment>
                                  <div
                                  style={{
                                    backgroundColor: "#172B4D",
                                    color: "#FFFFFF",
                                    borderRadius: "8px",
                                    width: "200px",
                                    margin: theme.spacing(-1),
                                    boxShadow: theme.shadows[5],
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px"
                                  }}
                                  >
                                  <table style={{ width: "100%", padding:"8px"}}>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "90%" }}>Password required</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Minimum 8 char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmMinimumChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmLowerCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmUpperCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 number</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmNumber && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmSpecialChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <IconButton>
                                <InfoIcon color="primary" onMouseOver={()=>{
                                  setTooltipConfirm(true)
                                }} />
                              </IconButton>
                            </HtmlTooltip>
                          </div>}
                      </InputAdornment>
                    ),
                  }}
                />  
              </div>
            </ThemeProvider>
          </div>}
          {successMsg && <div>
            <div style={{position:"relative", margin:"25px 0 0 0"}}>
              <img src={ElipsOne} alt=""  />
              <img src={ElipsTwo} alt="" style={{position:"absolute", left:"39.5%", top:"11px"}} />
              <img src={ElipsThree} alt="" style={{position:"absolute", left:"42.5%", top:"22px"}} />
              <img src={Vector} alt="" style={{position:"absolute", left:"47%", top:"40px"}}/>  
            </div>
            <h6
              style={{
              /*  marginBottom: "20px", */
                color: "#9b51e0",
                fontSize: "20px",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"600",
                lineHeight:"28px",
                padding: "10px 24px 10px 24px"
                /* borderBottom:"1px solid #EFF1F5" */
              }}
            >
              Success!
            </h6>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"normal",
                fontSize:"12px",
                lineHeight:"16px",
                padding:"0px 25px"
                }}>
              <p>Your password has been reset.</p>
            </div>
          </div>}
          {changePasswordStatus && <Button
            color="primary"
            variant="contained"
            id="signInButton"
            disabled={buttonStatus}
            style={{
              margin:"0px 24px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: buttonStatus === false? "#ffffff" : "#344563",
              padding:"8px 20px",
              borderRadius:"8px",
              backgroundColor:buttonStatus === false? "#9B51E0" : "#d1d6dd",
              textTransform:"none"
            }}
            onClick={changePassword}
          >
            Submit
          </Button>}
          {successMsg && <Button
              color="primary"
              variant="contained"
              id="signInButton"
              style={{
                margin:"10px 24px 0px 24px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "0.875rem",
                lineHeight: "1.75",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding:"8px 20px",
                borderRadius:"8px",
              textTransform:"none"
              }}
              onClick={login}
            >
              Go to Login
            </Button>}

         {!successMsg  && <div style={{padding:"24px", textAlign:"end"}}>
            <p style={{fontSize:"14px", fontFamily:"Poppins,sans-serif"}}> Back to <a onClick={login} style={{fontSize:"13px", color: "#9B51E0", textDecoration: "none", cursor:"pointer", textTransform:"uppercase", fontWeight:"bold"}}>Sign In</a></p>
          </div>}
        {!successMsg  && <ThemeProvider theme={theme}>
          <div style={{ 
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              padding:"20px 20px",
              color: "#344563"
              }}>
            <span>View our  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/privacy" target="_blank"> Privacy Policy</Link> &  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/terms" target="_blank"> Terms of Usage</Link>
            </span>
          </div>
        </ThemeProvider>}
          {loading && <LoginLoader />}
        </div>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
};
export default ProviderUserPassword;
