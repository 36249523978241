import React,{useState,useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import {atom,useRecoilState,useSetRecoilState} from 'recoil';

const StyledAlert = withStyles({
    root:{
        minWidth:'20vw',
        color:'#FFF',
        fontWeight:500,
        '& .MuiIconButton-label':{
            color:'#FFF'
        },
        '& .MuiAlert-icon':{
            color:'#FFF'
        }
    },
    standardSuccess:{
        background:'#9B51E0'
    },
    standardError:{
        background:'#f44336'
    },
    standardWarning:{
        background:'#ff9800'
    }
})(Alert)

// export const ToastAlertContext = createContext({
//     toastData:{
//       message:'',alertType:''
//     },
//     setToastData(){
  
//     }
//   })

export const ToastAlertData = atom({
    key:'toastAlertData',
    default:{
        message:'',alertType:''
    }
})

export const useToastAlertComponent = () => {
   return useSetRecoilState(ToastAlertData);
}

const ToastAlertComponent = () => {
    const [toastOpen,setToastOpen] = useState(false);
    const [enqueuedToastData,setEnqueueToastData] = useRecoilState(ToastAlertData)
    function handleClose(){
        setToastOpen(false)
        setEnqueueToastData({message:'',alertType:''})
    }
    useEffect(()=>{
       const {message} = enqueuedToastData;
       if(message){
        setToastOpen(true)
        return () => {
            setToastOpen(false)
        }
       }
    },[enqueuedToastData])
         const {message,alertType} = enqueuedToastData
        return (
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleClose} key={Math.random()} anchorOrigin={{vertical:'top',horizontal:'right'}} id="ToastAlert">
              <StyledAlert onClose={handleClose} severity={alertType}>{message}</StyledAlert>
            </Snackbar>
          )
}

// class ToastAlertComponent extends PureComponent {
//     constructor(props){
//         super(props);
//         this.state={
//           toastOpen:false,
//           toastData:{
//             message:'',alertType:''
//           }
//         };
//     }
//     componentDidUpdate(prevProps,prevState){
//         const {toastData:contextToastData} = this.context;
//         if(this.state.toastData!==contextToastData){
//             this.setState({
//                 toastOpen:true,
//                 toastData:contextToastData
//             })
//         }
//     }
//     handleClose = () => {
//             this.setState({
//                     toastOpen:false,
//                 })
//     }
//     render(){
//         const {toastOpen,toastData:{message,alertType}} = this.state;
//         return (
//           <Snackbar open={toastOpen} /*autoHideDuration={6000}*/ onClose={this.handleClose} key={Math.random()} anchorOrigin={{vertical:'top',horizontal:'right'}} id="ToastAlert">
//             <StyledAlert onClose={this.handleClose} severity={"success"}>{message}</StyledAlert>
//           </Snackbar>
//         )
//     }
// }

// ToastAlertComponent.contextType = ToastAlertContext;

export default React.memo(ToastAlertComponent);