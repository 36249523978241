import React, { useEffect, useState } from "react";
import {
  useHistory,
} from "react-router-dom";
import {
  TextField,
  Button,
  Link,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
/*import firebase from 'firebase';
import "firebase/auth";
import "firebase/analytics"
import "firebase/firestore"
import "firebase/functions";*/
import { usersID, firebaseIdGlobal as firebaseId, adminUserDetails } from "App";
import {getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence,getIdToken,signOut} from 'firebase/auth'
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import {useSetRecoilState} from "recoil";
import logo from "../../../../src/assets/images/Logo.png";
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircle from "@material-ui/icons/CheckCircle";
import {AES256_GCM_ENCRYPT} from "components/common/EncryptionAndDecryption_3";
import EmailValidator from 'email-validator';
import {PassValidation} from "../../common/PasswordValidation";
import clsx from 'clsx';

const useStyles = makeStyles({
  root:{
    paddingRight: '0px !important',
    borderRadius: '8px !important'
  }
});
const theme = createMuiTheme({
    MuiTextField:{
      root:{
        border: '1px solid #5E6C84',
        borderRadius: '10px'
      }
    },
   /*  MuiTypography:{
      colorPrimary:{
        color: '#344563'
      }
    }, */
    MuiIconButton:{
      root:{
        color:'#687587'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '12px',
        color: '#172B4D',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
      }
    },
    MuiLink:{
      root:{
        color:'#344563',
        /* textDecoration: Colors.lightBlue */
      },
      underlineHover:{
       /*  textDecoration: Colors.lightBlue */
      }
    }
})
const useDefaultButtonStyles = makeStyles({
  defaultButtonStyles: {
    height: 32,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600
  },
  defaultButtonStylesHover: {
    height: 32,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#9B51E0',
      color: '#FFF',
      border: '2px solid #9B51E0',
    }
  },
  invertedButtonStyles: {
    height: 40,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
  },
  invertedClass: {
    backgroundColor: '#9B51E0',
    color: '#FFF',
  },
  disabledButtonStyles: {
    border: '2px solid #8993A4 !important',
    backgroundColor: '#EFF1F5 !important',
    color: '#5E6C84 !important'
  },
  largerButton: {
    height: 40
  },
  widthBasis: {
    flexBasis: 191
  }
})
const approvalListtheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#9B51E0",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#172B4D",
    },
    success: {
      // This is green.A700 as hex.
      main: "#cb114c",
    },
  },
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiMenu: {
      paper: {
        border: '1px solid #5E6C84',
        borderRadius: 8
      }
    },
    MuiDialog: {
      paper: {
        minWidth: '42vw',
        borderRadius: 10
      }
    },
    MuiDialogTitle: {
      root: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '24px',
        borderBottom: '1px solid #EBECF0'
      }
    },
    MuiSelect: {
      icon: {
        top: 'initial',
        right: '10px'
      }
    },
    MuiDialogContentText: {
      root: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px'
      }
    },
  }
})
// const DialogButtonContainer = styled('div')({
//   columnGap: '10px',
//   display: 'grid',
//   justifyContent: 'end',
//   gridTemplateColumns: '100px 100px'
// })
// const DialogContainer = styled('div')({
//   height: 150,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-evenly',
//   padding: "0px 24px"
// })
// const ModalCaptionText = styled(Typography)({
//   font: '400 14px/143% Poppins'
// })

function LoginScreen({
  alert,
  userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  tokenId,
  setTokenId,
  refreshId,
  setRefreshId,
  /* setUserEmail,
  setUserPassword */
  userPermission,
  setUserPermission,
  /* setUserAccessId, */
  setFirebaseId,
  /*setUserSessionEmail,
  userSessionEmail,*/
  // setLoginFooter,
  setProviderUserLogin,
  setProviderUserId,
  setProviderUserToken,
  setProviderUserEmail,
  setProviderUserPassword,
  setAcceptedProvider,
  setLogoutUserToken,
  setLoginUserEmail,
  // setAuthToken,
  setPasswordExpired,
  setPasswordExpiredToken
}) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginAlert, setLoginAlert] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [open, setOpen] = useState(true);
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const setUsersID = useSetRecoilState(usersID)
  const setAdminUserDetails = useSetRecoilState(adminUserDetails)
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [lowerCase, setLowerCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [minimumChar, setMinimumChar] = useState(false)
  const [tooltip, setTooltip] = useState(false);
  const setFirebaseUId = useSetRecoilState(firebaseId)
  const [enableInfo, setEnableInfo] = useState(false)
  const [emailTicEnableDisable, setEmailTicEnableDisable] = useState(true);
  const [passTicEnableDisable, setPassTicEnableDisable] = useState(true);
  const { invertedButtonStyles,invertedClass } = useDefaultButtonStyles()
  const classes = useStyles();
  const [overridePopup, setOverridePopup] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState("");
  const [override, setOverride] = useState(false);
  const [expiredStatus, setExpiredStatus] = useState(false);
  const handleTooltipOpen = () => {
    setTooltip(true);
  };
  const handleTooltipClose = () => {
    setTooltip(false);
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
     /*  maxWidth: 250, */
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      minWidth: 200
    },
  }))(Tooltip);

  // useEffect(()=>{
  //   sessionStorage.clear()
  // },[])

  useEffect(()=>{
    //setLoginFooter(true)
    const auth = getAuth();
    auth.authStateReady().then(()=>{
      if(auth.currentUser){
        signOut(auth).then(()=>{
          window.location.reload();
        })
      }
    })
  },[])
  useEffect(() => {
      if (userId != null) {
        history.replace({pathname:'/homepage'});
      }
    // }
  }, [userId, app]);
  const handleDialogClose = () => {
    setOverridePopup(false)
    setOverride(false)
    setLoginAlert('')
  }
  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": refreshId
    })
    .then((res)=>{
      setTokenId(res.data.idToken)
      if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          setUserId(null)
          sessionStorage.clear();
          window.location.reload();
          //history.push("/login")
        }
      }
    })
  }
  const login = () => {
    setLoading(true);
    setOverrideMessage('');
    const userEmail = email.toLowerCase();
    setLoginAlert('')
    if(email === ''){
      setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>)
      setErrorEmail(true)
    }else{
      setErrorMsgEmail("")
      setErrorEmail(false)
    }
    if(password === ''){
      setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Enter Valid Pasword</span>)
      setErrorPassword(false)
    }else{
      setErrorMsgPassword("")
      setErrorPassword(true)
    }
    if(EmailValidator.validate(email)){
      setErrorMsgEmail('')
      setErrorEmail(false)
      if(PassValidation(password)){
        setErrorMsgPassword('')
        setErrorPassword(false)
        let param = {
          email: userEmail,
          password: password
        }
        const auth = getAuth();
        setPersistence(auth, browserSessionPersistence).then(()=>signInWithEmailAndPassword(auth,userEmail, password))
        .then(async (userCredentials) => {
          const firebaseUID = userCredentials.user.uid;
          const EncryptedRefreshToken = await AES256_GCM_ENCRYPT(userCredentials.user.refreshToken)
          setRefreshId(EncryptedRefreshToken)
          getIdToken(userCredentials.user,true).then(async function(idToken) {
            // Send token to your backend via HTTPS
            // ...
            const EncryptedToken = await AES256_GCM_ENCRYPT(idToken)
            setTokenId(EncryptedToken)
            // setAuthToken(EncryptedToken)

            const params = {
              email: userEmail,
              password: password
            }
            if(idToken){
                API.post("/commonEndpoints/v1/adminLogin", {
                  ...params,
                  "isOverride": override
                    // send the app name too
                },
                {
                  headers: {
                    'Authorization': EncryptedToken,
                    uid:firebaseUID
                  },
                })
                .then(res => {
                  if (res.data.status === true) {
                    setLoading(false);
                    res.data.adminLoginUser &&
                    res.data.adminLoginUser.userType &&
                    setUserType(res.data.adminLoginUser.userType);
                    const { fname, lname, firebaseUid } = res.data.adminLoginUser;
                    setUserId(email);
                    setFirebaseId(firebaseUid);
                    setUserDetails({ fname, lname });
                    res.data.adminLoginUser.roles && setUserPermission("active");
                    //setUserAccessId(res.data.user.id)
                    //setPermission(res.data.user.roles.map(({name})=>name))
                    //CHECK THIS COMMENT BEFORE DELETING ROLES
                    //setRoles(res.data.user.roles.map(({name})=>name))
                    setUsersID(res?.data?.adminLoginUser?.id)
                    setFirebaseUId(res?.data?.adminLoginUser?.firebaseUid)
                    //setLoginFooter(false)
                    setLogoutUserToken(res?.data?.adminLoginUser?.token)
                    setAdminUserDetails(res?.data?.adminLoginUser)
                    setLoginUserEmail(email)
                  } else {
                    setLoading(false);
                    setOpen(true);
                    setLoginAlert(res.data.errorMessage);
                    if(res.data.errorMessage === 'User already logged in.'){
                      setOverrideMessage(res.data.errorMessage);
                    }
                  }
                  if(res.data.errorId === 107){
                    setProviderUserLogin(true)
                    setAcceptedProvider(false)
                    history.push("/app/termsandconditions")
                    setProviderUserId(res.data.userId)
                    setProviderUserToken(res.data.token)
                    setProviderUserEmail(res.data.email)
                    setProviderUserPassword(password)
                  }else if(res.data.errorId === 108){
                    setAcceptedProvider(true)
                    history.push("/app/providerpassword")
                    setProviderUserLogin(true)
                    setProviderUserId(res.data.userId)
                    setProviderUserToken(res.data.token)
                    setProviderUserEmail(res.data.email)
                    setProviderUserPassword(password)
                  }
                  if(res.data.errorId === 110){
                    setExpiredStatus(true)
                    setOpen(false)
                    setLoginAlert('');
                    setPasswordExpiredToken(res.data.token)
                    setLoginUserEmail(email)
                  }
                })
                .catch( (error)=> {
                  setLoading(false);
                  if ((error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
                    refreshToken();
                  } else if(error.response && error.response.status === 504){
                    refreshToken();
                    setLoginAlert('Server did not response please try again...')
                  } else if(error.response && error.response.status === 500){
                    refreshToken();
                    setLoginAlert('')
                  } else if(error.response && error.response.status === 502){
                    refreshToken();
                  }
                });
              }
          }).catch((error) => {
            setLoading(false);
            // Handle error
          });
        })
        .catch((error)=> {
          if(error.code === 'auth/user-disabled'){
            API.post("userEndpoints/v1/adminLoginFailed", {
              ...param
            }).then((res)=>{
              setLoginAlert(res.data.errorMessage);
              setOpen(true)
            })
          }
          setLoading(false);
          if ((error && error.code === 'auth/user-not-found')||error.code === 'auth/wrong-password'||error.code === 'auth/too-many-requests') {
            setLoginAlert("Please enter valid email or password");
            setOpen(true)
          }
          //  else if(error && error.code === 'auth/wrong-password'){
          //   setLoginAlert("Please enter valid email or password");
          //   setOpen(true)
          // } 
          // else if(error && error.code === 'auth/too-many-requests'){
          //   setLoginAlert("Please enter valid email or password");
          //   setOpen(true)
          // }
        })
        } else{
          setLoading(false);
          /* setErrorMsgPassword(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>) */
        }
      } else{
        setLoading(false);
        setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>)
        setErrorEmail(true)
      }
  };
  useEffect(()=>{
    if(override){
      login()
    }
  },[override])
  useEffect(()=>{
    if(overrideMessage !==''){
      setOverridePopup(true)
    }
  },[overrideMessage])
  const keyPress=(event)=>{
    const k = event ? event.which : window.event.keyCode;
    if(k===13){
      login()
    }
  }
  const forgetPassword = () =>{
    history.push("/app/forgotpassword")
  }
  const handleClose = () => {
    setExpiredStatus(false)
    setPasswordExpired(true)
    history.push("/app/forgotpassword")
  };
  return (
    <div style={{display:"grid"}}>
      {loginAlert !== "" && loginAlert !== 'User already logged in.' && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "auto",
          width: "100%",
          display: "initial",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{padding:"0px 0 0 0"}}>
          <div 
            style={{display:"initial",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"0px"
          }}>
            <img src={logo} />
          </div>
          <div 
          style={{fontFamily:"Poppins",
          fontStyle:"normal",
          fontWeight:"normal",
          fontSize:"14px",
          lineHeight:"20px",
          width:"490px",
          margin:"auto",
          padding:"0px 0 30px 0"
          }}>
            <p>Welcome to Omnicure Command Center</p>
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            /* justifyContent: "space-evenly", */
            display: "flex",
            flexDirection: "column",
            borderRadius:"10px",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              /* padding: "24px", */
              width: "365px",
              minHeight: "420px"
            }
          }}
        >
          <h6
            style={{
             /*  marginBottom: "20px", */
              color: "grey",
              fontSize: "14px",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"600",
              lineHeight:"20px",
              padding: "24px 24px 0 24px"
              /* borderBottom:"1px solid #EFF1F5" */
            }}
          >
            Login
            {/* Welcome to Omnicure Command Center */}
          </h6>
          <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div>
          <ThemeProvider theme={theme}>
          <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
            <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px"
              }}>
              Email address
            </span>
              <TextField
                placeholder="Email"
                size="small"
                margin="dense"
                error={errorEmail}
                helperText={errorMsgEmail}
                style={{borderRadius:"7px", height:"40px"}}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                  setEmailValidation(false)
                  if(EmailValidator.validate(event.target.value)){
                    setEmailValidation(true)
                    setErrorEmail(false)
                    setErrorMsgEmail('')
                    setEmailTicEnableDisable(true)
                  }else{
                    setEmailTicEnableDisable(false)
                  }
                }}
                onBlur={()=>{
                  if(!emailValidation){
                    setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>);
                    setErrorEmail(true)
                  } else{
                    setErrorMsgEmail('')
                    setErrorEmail(false)
                  }
                }}
                variant="outlined"
                /* helperText={errorMsgEmail} */
                InputProps={{
                  classes:classes,
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end" style={{borderRadius:"12px !important"}}>
                      <IconButton>{emailValidation && email !=='' && <CheckIcon color="primary" />}</IconButton>
                    </InputAdornment>
                  ),
                }}
              /> 
            <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px",
              marginTop:"10px"
              }}>
              Password
            </span>
            <TextField
              classes={classes}
              style={{ borderRadius:"7px", height:"40px"}}
              error={errorPassword}
              helperText={errorMsgPassword}
              value={password}
              placeholder="Password"
              arial-label="password"
              size="small"
              margin="dense"
              onKeyPress={keyPress}
              onChange={(event) => {
                setPassword(event.target.value)
                setPasswordValidation(false)
                setLowerCase(false)
                setUpperCase(false)
                setSpecialChar(false)
                setNumber(false)
                setMinimumChar(false)
                if(PassValidation(event.target.value)){
                  setPassTicEnableDisable(true)
                  setErrorPassword(false)
                  setErrorMsgPassword('')
                }else{
                  setPassTicEnableDisable(false)
                }
                if(PassValidation(event.target.value)){
                  setPasswordValidation(true)
                }
                if(event.target.value.match(/[a-z]/)){
                  setLowerCase(true)
                }
                if(event.target.value.match(/[A-Z]/)){
                  setUpperCase(true)
                }
                if(event.target.value.match(/[@$!%*?&]/)){
                  setSpecialChar(true)
                }
                if(event.target.value.match(/[0-9]/)){
                  setNumber(true)
                }
                if(event.target.value.length>=8){
                  setMinimumChar(true)
                }               
              }}
              onBlur={()=>{
                if(!passwordValidation){
                  setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid pasword. Try Again</span>)
                  setErrorPassword(true)
                  setEnableInfo(true)
                } else{
                  setErrorMsgPassword('')
                  setErrorPassword(false)
                  setEnableInfo(false)
                }
              }}
             /*  label="Password" */
             /* placeholder="Password" */
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              variant="outlined"
              /* helperText={errorMsgPassword} */
              InputProps={{
                // <-- This is where the toggle button is added.
                classes:classes,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      color="primary"
                    >
                      {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                    </IconButton>
                    {passTicEnableDisable && password !=='' &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                    {/* {errorMsgPassword && <ClickAwayListener onClickAway={handleTooltipClose}> */}
                      {enableInfo && !passTicEnableDisable && <div>
      
                        <HtmlTooltip
                          onMouseOut={handleTooltipClose}
                            open={tooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                            <React.Fragment>
                              <div
                              style={{
                                backgroundColor: "#172B4D",
                                color: "#FFFFFF",
                                borderRadius: "8px",
                                width: "200px",
                                margin: theme.spacing(-1),
                                boxShadow: theme.shadows[5],
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "12px",
                                lineHeight: "16px"
                              }}
                              >
                              <table style={{ width: "100%", padding:"8px"}}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "90%" }}>Password required</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Minimum 8 char</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {minimumChar && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {lowerCase && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {upperCase && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 number</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {number && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {specialChar && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <IconButton>
                            <InfoIcon color="primary" onMouseOver={handleTooltipOpen} />
                          </IconButton>
                        </HtmlTooltip>
                      </div>}
                    {/* </ClickAwayListener>} */}
                    {/* <IconButton>
                      {passwordValidation && <CheckIcon color="primary" />}
                    </IconButton> */}
                  </InputAdornment>
                ),
              }}
            /> 
            {/* <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px"
              }}>
              {errorMsgPassword}
            </span> */}
          
            <div>
           {/*  <FormControl 
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                float:"left"
                }}
              >
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary"  onChange={rememberCheck}/>}
                  label="Remember Me"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl> */}
            <Link 
            onClick={forgetPassword} 
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "16px",
              float:"initial",
              marginTop:"10px",
              color: "#172B4D",
              cursor:"pointer",
              textDecoration:"underline"
              }}>
              Forgot Password ?
            </Link>
            </div>
          </div>
          </ThemeProvider>  
          <Button
            color="primary"
            variant="contained"
            onClick={login}
            id="signInButton"
            disabled={emailTicEnableDisable && passTicEnableDisable? false : true}
            style={{
              margin:"0px 24px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#FFFFFF",
              padding:"8px 20px",
              borderRadius:"8px",
            }}
          >
            Login
          </Button>
        <ThemeProvider theme={theme}>
          <div style={{ 
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              padding:"20px 20px",
              color: "#344563"
              }}>
            <span>View our  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/privacy" target="_blank"> Privacy Policy</Link> &  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/terms" target="_blank"> Terms of Usage</Link>
            </span>
          </div>
        </ThemeProvider>
          {loading && <LoginLoader />}
          {/* <div className="hrline">or</div>
                        <NavigationLink to="/app/sign-up"><Button style={{width: 'fit-content',alignSelf:'center',marginBottom:'20px'}} color="primary" variant="contained">Sign Up</Button></NavigationLink>
                        <NavigationLink to="/app/forgetPassword"><Link component="button" underline="hover" style={{fontSize:'14px',color:'#808080'}}>Forgot Password ?</Link></NavigationLink> */}
        </div>
      </div>
      <div style={{ minWidth: "auto" }}>
        <ThemeProvider theme={approvalListtheme} >
          <Dialog open={overridePopup} scroll={"paper"}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} disableTypography>
              <span>Continue ?</span>
              <span className={classes.closeIcon} onClick={handleDialogClose} style={{ position: 'relative', top: 7, left: 16 }}></span>
            </DialogTitle>
            <DialogContent>
               <>
                  <DialogContentText variant='caption'>User already logged in Please click Yes to override.</DialogContentText>
               </>
            </DialogContent>
            <DialogActions>
                  <Button color="default"className={clsx(invertedButtonStyles)} onClick={() => { handleDialogClose() }} >No</Button>
                  <Button color="primary" className={clsx(invertedButtonStyles, invertedClass)}  variant="contained" onClick={() => {
                    setOverride(true)
                    setOverridePopup(false)
                  }}>Yes</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
      <div>
        <Dialog
          open={expiredStatus}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Password Expired"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
          {' Your passsword has expired. please change it to access your account.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
}

export default LoginScreen;
