import React from "react";
import {usersLoginToken} from 'App';
import {useSetRecoilState} from 'recoil';

function SuccessScreen({ setUserId, userId }) {
  //const history = useHistory();
  const setLoginUserToken = useSetRecoilState(usersLoginToken)
  const login = () => {
    setUserId(null);
    setLoginUserToken(null)
    //history.push("/login");
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          padding: "100px 0px",
        }}
      >
        <div
          style={{
            padding: "50px",
            margin: "auto",
            backgroundColor: "white",
            justifyContent: "space-evenly",
            display: "flex",
            minWidth: "400px",
            flexDirection: "column",
            fontSize: "20px",
          }}
        >
          <span>
            New Password Successfully Updated{" "}
            <a
              style={{ cursor: "pointer", color: "#9b51e0" }}
              onClick={() => {
                login();
              }}
            >
              Login
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SuccessScreen;
