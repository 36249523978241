let passwordValidator = require('password-validator');
export const PassValidation=(res)=>{
    let schema = new passwordValidator();
    schema.is().min(8).is().max(16).has().uppercase().has().lowercase().has().digits().has().symbols();
    return schema.validate(res)
}
export const Lowercase=(res)=>{
    var value = new passwordValidator();
    value.has().lowercase(8);
    return value.validate(res)
}
export const Uppercase=(res)=>{
    var value = new passwordValidator();
    value.has().uppercase();
    return value.validate(res)
}
export const Symbols=(res)=>{
    var value = new passwordValidator();
    value.has().symbols();
    return value.validate(res)
}
export const SpecialNumber=(res)=>{
    var value = new passwordValidator();
    value.not().symbols().not().digits();
    return value.validate(res)
}
