import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { sort, assocPath } from "ramda";
import { TextField, Button, Link, Input,Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import memoize from "memoize-one";
import CustomLoader from "../../common/CustomLoader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from 'moment';
import clsx from 'clsx';
import API from "../../../api";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from "components/common/Pagination";
import {firebaseIdGlobal as firebaseId,usersID} from 'App';
import {useRecoilValue} from 'recoil';

const useStatusToolbar = makeStyles({
  StatusToolbar: {
    backgroundColor: "#F7F8FA",
    textAlign: "center",
    minHeight: "12px",
    font: "12px sans-serif",
    color: "black",
    borderRadius: '5px',
    border: '1px solid #dadde9'
  }
})
const AlertTooltip = ({children}) => {
  const {StatusToolbar,AddressToolbar} = useStatusToolbar();
  const [tooltipOpen,setTooltipOpen] = useState(false)
  return (
    <>
    <Tooltip open={tooltipOpen} title={children} classes={{tooltip:clsx(StatusToolbar,AddressToolbar)}} placement="top" onOpen={({target:{scrollHeight,clientHeight}})=>{
                if(scrollHeight>clientHeight){
                  setTooltipOpen(true)
                }
                }} onClose={()=>{
                  setTooltipOpen(false)
                }}><p className="line-clamp" style={{position:'relative'}}>{children}</p></Tooltip>
    </>
  )
}
const subHeader = React.createRef();
const TextContainerStyles = {
  messageElement: {
    display: 'box',
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  expandLess: {
    lineClamp: 2,
  },
  expandMore: {
    lineClamp: 'unset'
  }
}
const atagtolink = makeStyles({
  aTagToLink: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "underline",
  }
})
const TextContainer = (props) => {
  const [expand, setExpansion] = useState(false)
  const [toggle, setToggle] = useState(false)
  const msgElement = useRef(null);
  const { classes } = props;
  useEffect(() => {
    msgElement.current?.scrollHeight > msgElement.current?.clientHeight && setToggle(true);
  }, [])
  return (<div><div className={clsx({ [classes.messageElement]: true, [classes.expandMore]: expand, [classes.expandLess]: !expand })} ref={msgElement} dangerouslySetInnerHTML={{ __html: props.message }}></div>
    {toggle && <Link
      component="button"
      variant="body2"
      onClick={() => {
        setExpansion(!expand)
      }}
    >
      {expand ? 'Show Less' : 'Show More'}
    </Link>}</div>)
}

const StyledTextContainer = withStyles(TextContainerStyles)(TextContainer)

const columns = memoize((callfunc, aTagToLink, titleHead,tileShow) => [{
  name: tileShow,
  selector: 'title',
  wrap: true,
  grow: 0,
  width: '250px',
  cell:({title})=><AlertTooltip>{title}</AlertTooltip>,
  omit: titleHead
}, {
  name: 'Message',
  selector: 'alertMsg',
  wrap: true,
  minWidth: '550px',
  grow: 1,
  cell: ({ alertMsg }) => <StyledTextContainer message={alertMsg} />
},
{
  name: 'User',
  selector: 'senderName',
  wrap: true,
  grow: 0,
  cell:({senderName})=><AlertTooltip>{senderName}</AlertTooltip>,
  width: '250px'
},
{
  name: 'Send To',
  selector: 'ntype',
  width: '250px',
  cell: (row) => (
    <a
      variant="contained"
      className={clsx(aTagToLink)}
      onClick={() => {
        callfunc(row);
      }}
    >
      {row.ntype}
    </a>
  ),
  button: true,
},
{
  name: 'Date and Time',
  selector: 'createdTime',
  wrap: true,
  grow: 0,
  format: ({ createdTime }) => createdTime?moment(Number(createdTime)).format('MMM DD YYYY, hh:mm A'):'--',
  width: '200px',
}])

const useFilterComponentStyles = makeStyles({
  inputWidth: {
    width: "80%",
  },
  inputDesktop: {
    fontFamily:'Poppins',
    maxWidth:440,
    width:'30vw',
    background: "#FFFFFF",
    height:40,
border: '1px solid #8993A4',
borderRadius: "10px"
  }
});

const FilterComponent = React.forwardRef(({ filterText, onFilter, onClear },ref) => {
  const screenMediaQuery = useMediaQuery(`(max-width:635px)`);
  const classes = useFilterComponentStyles();
  
  return (
    <>
    {screenMediaQuery?(<div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        zIndex:1300,
        marginBottom: screenMediaQuery ? 15 : 0,
      }}
    >
      <TextField
        id="search"
        type="text"
        placeholder="Search By Name"
        className={classes.inputWidth}
        value={filterText}
        onChange={onFilter}
      />
      <ClearIcon onClick={onClear} />
    </div>):<div
      id="topInputElement"
      className={classes.inputTransition}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        zIndex:1300,
        marginBottom: screenMediaQuery ? 15 : 0,
        /*transition:'195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',*/
      }}
    >
      <Input
        id="search"
        type="search"
        disableUnderline
        placeholder="Search"
        startAdornment={<div style={{margin:'0 12px 0 19px',display:'flex',alignItems:'center'}}><SearchIcon /></div>}
        className={classes.inputDesktop}
        value={filterText}
        onChange={onFilter}
      />
    </div>}</>
  )});

const SubHeaderComponentMemo = ({
  handleClear,
  filterText,
  filterTextset,
  msgType,

}) => {
  const filterSearchRef = useRef(null);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
      ref={subHeader}
    >
      {msgType !== "Sms" ?
      <FilterComponent
        onFilter={filterTextset}
        onClear={handleClear}
        filterText={filterText}
        ref={filterSearchRef}
      />
      :""}
    </div>
  );
};



const AlertList = ({ loading, tokenId, refreshId, setTokenId, setUserId, setExpiredMsg, LoginusersID, msgType, tblTile }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [progressPending, setProgressPending] = useState(true);
  const [popupTableValue, setpopupTableValue] = useState("");
  const [lengthOfUserList, setlengthOfUserList] = useState([]);
  const firebaseuid=useRecoilValue(firebaseId)
  const usersuid = useRecoilValue(usersID)
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { aTagToLink } = atagtolink();
  let titleHead = false;
  let tileShow = "Title"
  if(msgType === "Sms"){
    titleHead = true;
  }else if(msgType === "Email"){
     tileShow = "Subject"
  }
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const tableRef = useRef(null);

  const SendToPopup = (val) => {

    val['ntype']= val.type.charAt(0).toUpperCase() + val.type.slice(1)
    setlengthOfUserList(val.selectedUserList)
    setpopupTableValue(val)
    setOpen(true);
  }


  const handleClose = () => {
    setOpen(false);
  };

    const retryApiGet = (url,header)=>{
      return API.get(url,assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
        if(res?.headers?.userid===String(usersuid)){
          return Promise.resolve(res)
        }else{
          throw new Error("Invalid Response")
        }
      }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
        return new Promise(resolve=>{
          API.post("/userEndpoints/v1/renewIdToken",{
            "token": refreshId
          })
          .then((res)=>{
            setTokenId(res.data.idToken)
            if(!res.data.idToken){
              setExpiredMsg(true)
              throw error
            }else{
              resolve(API.get(url,{headers:{
                Authorization:res.data.idToken,
                uid:firebaseuid
              }}).then((res)=>{
               // console.log("Inside Second Call")
                if(res?.headers?.userid===String(usersuid)){
                  return Promise.resolve(res)
                }else{
                  throw new Error("Invalid Response")
                }
              }))
            }
          })
          .catch((error) =>{return Promise.reject(error)})
        })
      }else{
        throw error
      }})
    }

    const retryApiPost = (url,data,header)=>{
      return API.post(url,{...data,id:usersuid},assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
        if(res?.headers?.userid===String(usersuid)){
          return Promise.resolve(res)
        }else{
          throw new Error("Invalid Response")
        }
      }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
        return new Promise(resolve=>{
          API.post("/userEndpoints/v1/renewIdToken",{
            "token": refreshId
          })
          .then((res)=>{
            setTokenId(res.data.idToken)
            if(!res.data.idToken){
              setExpiredMsg(true)
              throw error
            }else{
              resolve(API.post(url,{...data,id:usersuid},{headers:{
                uid:firebaseuid,
                Authorization:res.data.idToken
              }}).then((res)=>{
                if(res?.headers?.userid===String(usersuid)){
                  return Promise.resolve(res)
                }else{
                  throw new Error("Invalid Response")
                }
              }))
            }
          })
          .catch((error) =>{Promise.reject(error)})
        })
      }else{
        Promise.reject(error)
      }})
    }
    useEffect(() => {
      const tableElement = tableRef.current;
      //tableRef.current.firstChild.style.width = `${tableRef.current.querySelector('[role="table"]').clientWidth}px`
      var ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          tableElement.querySelector('[role="table"]').style.width=`${cr.width}px`;
        }
      });
      
      ro.observe(tableElement)
      return ()=>{
        ro.unobserve(tableElement)
      }
    }, [])
  // useEffect(() => {
  //   if (window.innerWidth > 636) {
  //     //tableRef.current.firstChild.style.width = `${tableRef.current.querySelector('[role="table"]').clientWidth}px`
  //     var ro = new ResizeObserver(entries => {
  //       for (let entry of entries) {
  //         const cr = entry.contentRect;
  //         tableRef.current.firstChild.style.minWidth = `${cr.width}px`;
  //       }
  //     });
  //     ro.observe(tableRef.current.querySelector('[role="table"]'))
  //     return () => {
  //       ro.unobserve(tableRef.current.querySelector('[role="table"]'))
  //     }
  //   }
  // }, [])
  // API.interceptors.response.use(
  //   function(response) {
  //     // If the request succeeds, we don't have to do anything and just return the response
  //     return response
  //   },
  //   function(error) {
  //     console.log(error.response,"Check The Error")
  //     const errorResponse = error.response
  //     if([401,500,502,504,703,704].includes(errorResponse.status)){
  //       return new Promise(resolve=>{
  //         API.post("/userEndpoints/v1/renewIdToken",
  //            {
  //                "token": refreshId
  //            }).then(res=>{
  //             setTokenId(res.data.idToken)
  //             if(!res.data.idToken){
  //               setExpiredMsg(true)
  //               Promise.reject()
  //             }
  //             errorResponse.config.headers.Authorization = res.data.idToken
  //             resolve(axios(errorResponse.config))
  //            }).catch(error=>{
  //              Promise.reject(error)
  //            })
  //       })
  //     }
  //     // if (isTokenExpiredError(errorResponse)) {
  //     //   return resetTokenAndReattemptRequest(error)
  //     // }
  //     // If the error is due to other reasons, we just throw it back to axios
  //     return Promise.reject(error)
  //   }
  // )
  //useEffect(()=>{
  //   API.get(`/adminUserEndpoints/v1/getSystemAlerts`).then(({data:{systemAlertList}})=>{
  //     const DateTimeSorted = sort(function(a,b){
  //       return new Date(Number(a.createdTime)) - new (b.createdTime))
  //     },systemAlertList)
  //     setAlertData(DateTimeSorted);
  //     const filteredDataOutput = DateTimeSorted.filter(({title})=>title.startsWith(filterText))
  //     setFilteredData(filteredDataOutput)
  //     setProgressPending(false)
  //   })
  // },[])
  // useEffect(()=>{
  //   const refreshAuthLogic = failedRequest =>
  //   API.post("/userEndpoints/v1/renewIdToken",{   
  //     "token": refreshId
  //   })
  //   .then((res)=>{
  //     var tokenId = res.data.idToken
  //     setTokenId(res.data.idToken)
  //     if(!res.data.idToken){
  //       setExpiredMsg(true)
  //       //console.log('expiredLogin', expiredLogin)
  //       //if(expiredLogin){          
  //         //setUserId(null)
  //         //history.push("/login")
  //         return Promise.reject();
  //       //}
  //     }
  //     failedRequest.response.config.headers["Authorization"] = tokenId;
  //   return Promise.resolve();
  //   })
  //   .catch((error) =>{
  //     console.log("refresh fail");
  //     return Promise.reject(error);
  //   })
  //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
  // })

  useEffect(() => {
    if (!loading) {
    retryApiPost(`/adminUserEndpoints/v1/getSystemAlerts`,{
        msgType: msgType
      },{
        headers: {
          'Authorization': tokenId,
        }
      }).then(({ data: { systemAlertList = [] } }) => {
        let DateTimeSorted = sort(function (a, b) {
          return new Date(Number(b.createdTime)) - new Date(Number(a.createdTime))
        }, systemAlertList)
        setAlertData(DateTimeSorted);
        const filteredDataOutput = DateTimeSorted.filter(({ title }) => title.startsWith(filterText))
        filteredDataOutput.forEach(function (element) {
          element.ntype = element.type.charAt(0).toUpperCase() + element.type.slice(1);
        });
        setFilteredData(filteredDataOutput)
        setProgressPending(false)
      })
    }
  }, [loading])

  useEffect(() => {
    const filteredDataOutput = alertData.filter(({ title }) => title.startsWith(filterText))
    filteredDataOutput.forEach(function (element) {
      element.ntype = element.type.charAt(0).toUpperCase() + element.type.slice(1);
    });
    setFilteredData(filteredDataOutput)
   // console.log('filteredDataOutput', filteredDataOutput)
  }, [filterText])
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  const filterTextset = (e) => {
    setFilterText(e.target.value);
  };

  const screenMediaQuery = useMediaQuery("(max-width:635px)");
  return (
    <div id="alertlistTable" style={{ width: "100%" }}>
      {/*  <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div> */}
      {screenMediaQuery && (
        <SubHeaderComponentMemo
          {...{ handleClear, filterTextset, filterText,msgType }}
        />
      )}
      <div style={{ backgroundColor: "#FFFFFF", width: "100%" }} ref={tableRef}>
        <DataTable
           style={{
            margin: "0 auto",
            textAlign: "left",
            color:'#172B4D',
            display:'grid',
            fontFamily: 'Poppins',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '-0.2px'
          }}
          customStyles={{header:{style:{fontSize:18,fontWeight:600,backgroundColor:'initial',paddingLeft:0}},table:{style:{backgroundColor:'inherit',display: "grid",overflowX: "auto",borderBottom: 0,borderTopLeftRadius: 16,borderTopRightRadius: 16,border: "1px solid #D1D6DD",...(loading || filteredData.length === 0) && {borderBottomLeftRadius:16,borderBottomRightRadius:16},"&>div:nth-child(2)": { overflowX: "hidden" },}},tableWrapper:{style:{marginTop:16,tableLayout:"fixed"}},headRow:{style:{height:56}},subHeader:{style:{flexDirection:'column',rowGap:10,justifyContent:'flex-start',alignItems:'start',backgroundColor:'inherit',paddingLeft:0}},headCells:{style:{fontSize:14}},cells:{style:{minHeight:"50px"}},rows:{style:{minHeight:'intial','&:not(:last-of-type)':{
            borderBottom:'1px solid #ededed'
          }}},
          noData:{style:{border:'1px solid #D1D6DD'}}}}
          sortIcon={<ArrowDownwardIcon />}
          title={tblTile}
          columns={columns(SendToPopup, aTagToLink ,titleHead,tileShow)}
          data={filteredData}
          progressPending={progressPending}
          progressComponent={<CustomLoader />}
          noDataComponent={
            <p
              style={{
                fontSize: "1.5rem",
                margin: "20px",
              }}
            >
              No records found.
            </p>
          }
          fixedHeader
          fixedHeaderScrollHeight="300px"
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader={!screenMediaQuery}
          subHeaderComponent={
            !screenMediaQuery && (
              <SubHeaderComponentMemo
                {...{ handleClear, filterTextset, filterText, msgType}}
              />
            )
          }
          persistTableHead
          paginationComponent={Pagination}

        />

        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{style:{width:"auto"}}}
        >
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                {popupTableValue && popupTableValue.type === "all" ?
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell >
                          {popupTableValue.type.charAt(0).toUpperCase() + popupTableValue.type.slice(1)}
                        </TableCell>
                        <TableCell >
                          {popupTableValue.type.charAt(0).toUpperCase() + popupTableValue.type.slice(1)}
                          </TableCell>
                        <TableCell >
                          {popupTableValue.type.charAt(0).toUpperCase() + popupTableValue.type.slice(1)}
                          </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  :
                  <Table aria-label="simple table">
                          <TableBody>
                            {lengthOfUserList.map((item, index) => (
                              popupTableValue.selectedUserList[index] ?
                                <TableRow>
                                  {!index ?
                                   <TableCell >
                                      {popupTableValue.type.charAt(0).toUpperCase() + popupTableValue.type.slice(1)}
                                  </TableCell>
                                    :
                                    <TableCell >-</TableCell>
                                    }
                                  <TableCell key={item} >{popupTableValue.selectedUserList[index].providerType}</TableCell>
                                  <TableCell key={item} >{popupTableValue.selectedUserList[index].name}</TableCell>
                               </TableRow>
                                :
                                <TableRow>
                                  <TableCell key={item} >No Records Found!</TableCell>
                                </TableRow>
                            ))}
                          </TableBody>
                      </Table>
                   
                }
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{"padding-right": "36px"}}>
            <Button
              variant="contained"
              onClick={handleClose}
              color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default AlertList;