import React from 'react';
import { Select,MenuItem } from '@material-ui/core';
import {times} from 'ramda';
import {styled,makeStyles} from '@material-ui/core/styles'

const ArrowWrapper = styled('div')({display:'flex',justifyContent:'center',border:'1px solid #D1D6DD',borderRadius:8,width:32,height:32,margin:'auto 0',pointerEvents:({disabled})=>disabled?'none':'auto',cursor:({disabled})=>disabled?'unset':'pointer','& .chevron::before':{
	borderColor:({disabled})=>disabled?'rgba(0, 0, 0, 0.18)':'#344563'
}})

const usePaginationStyles = makeStyles({
	menuWrapper:{border: "1px solid #5E6C84",
	boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
	borderRadius: '8px'},
})

function getNumberOfPages(rowCount, rowsPerPage){return Math.ceil(rowCount / rowsPerPage)}


function Pagination({
	rowsPerPage,
	rowCount,
	currentPage,
	onChangeRowsPerPage,
	onChangePage
}) {
	//const isRTL = useRTL(direction);
	// const isRTL = detectRTL(direction);
	const numPages = getNumberOfPages(rowCount, rowsPerPage);
	const lastIndex = currentPage * rowsPerPage;
	const firstIndex = lastIndex - rowsPerPage + 1;
	const disabledLesser = currentPage === 1;
	const disabledGreater = currentPage === numPages;
	const range =
		currentPage === numPages
			? `${firstIndex}-${rowCount} of ${rowCount}`
			: `${firstIndex}-${lastIndex} of ${rowCount}`;

	const handlePrevious = React.useCallback(() => onChangePage(currentPage - 1), [currentPage, onChangePage]);
	const handleNext = React.useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);
	const handleRowsPerPage = (e) => onChangeRowsPerPage(Number(e.target.value), currentPage);

    const navigateToParticularPage=(e) => onChangePage(Number(e.target.value));

	const {menuWrapper} = usePaginationStyles();

	const selectOptions = [10,15,20,25,30].map((num) => (
		<MenuItem key={num} value={num}>
			{num}
		</MenuItem>
	));

	const NoOfPagesSelect = (
		<Select onChange={handleRowsPerPage} defaultValue={rowsPerPage} MenuProps={{classes:{paper:menuWrapper}}} disableUnderline={true} style={{border:'1px solid #D1D6DD',width:65,height:32,borderRadius:8,textAlign:'center',fontFamily:'Poppins'}}>
			{selectOptions}
		</Select>
	);

    const NavigateToPage = (
        <Select onChange={navigateToParticularPage} value={currentPage} disableUnderline={true} MenuProps={{classes:{paper:menuWrapper}}} style={{border:'1px solid #D1D6DD',width:53,height:32,borderRadius:8,textAlign:'center',fontFamily:'Poppins'}}>
            {times(n=>n+1,numPages).map(num=><MenuItem key={num} value={num}>{num}</MenuItem>)}
        </Select>
    )

	return (
        <>
        <nav style={{fontSize:14,display:'flex',height:56,lineHeight:'56px',width:'100%',backgroundColor:'#FFF',border:'1px solid #D1D6DD',borderTop:0,borderBottomLeftRadius:16,borderBottomRightRadius:16,padding:'0 24px',columnGap:16,color:'#344563'}}>
            <div style={{flexGrow:1,textAlign:'left'}}>{range}</div>
            <div>Items Per Page</div>
            <div style={{margin:'auto 0'}}>{NoOfPagesSelect}</div>
            <ArrowWrapper onClick={handlePrevious} disabled={disabledLesser}><span className="chevron left"></span></ArrowWrapper>
            <div>Page</div>
			<div>{NavigateToPage}</div>
            <ArrowWrapper onClick={handleNext} disabled={disabledGreater}><span className="chevron right"></span></ArrowWrapper>
        </nav>
		{/*<PaginationWrapper className="rdt_Pagination">
			{!options.noRowsPerPage && shouldShow && (
				<>
					<RowLabel>{options.rowsPerPageText}</RowLabel>
					{select}
				</>
			)}
			{shouldShow && <Range>{range}</Range>}
			<PageList>
				<Button
					id="pagination-first-page"
					type="button"
					aria-label="First Page"
					aria-disabled={disabledLesser}
					onClick={handleFirst}
					disabled={disabledLesser}
					isRTL={isRTL}
				>
					{paginationIconFirstPage}
				</Button>

				<Button
					id="pagination-previous-page"
					type="button"
					aria-label="Previous Page"
					aria-disabled={disabledLesser}
					onClick={handlePrevious}
					disabled={disabledLesser}
					isRTL={isRTL}
				>
					{paginationIconPrevious}
				</Button>

				{!shouldShow && select}

				<Button
					id="pagination-next-page"
					type="button"
					aria-label="Next Page"
					aria-disabled={disabledGreater}
					onClick={handleNext}
					disabled={disabledGreater}
					isRTL={isRTL}
				>
					{paginationIconNext}
				</Button>

				<Button
					id="pagination-last-page"
					type="button"
					aria-label="Last Page"
					aria-disabled={disabledGreater}
					onClick={handleLast}
					disabled={disabledGreater}
					isRTL={isRTL}
				>
					{paginationIconLastPage}
				</Button>
			</PageList>
            </PaginationWrapper>*/}</>
	);
}

export default Pagination;